// import React from 'react';
// import { Link } from 'react-router-dom';

// const BottomNavbar = () => {
//     const IsloggedIn = localStorage.getItem('accessToken')
//   return (
//     <div className="container-fluid">
//       <nav className="navbar fixed-bottom navbar-dark bg-dark d-block d-lg-none p-2">
//         <ul className="navbar-nav ml-auto mr-auto d-flex flex-row justify-content-between" style={{ width: '100%' }}>
//           <li className="nav-item">
//             <Link className="nav-link rotatingBorder" to="/">
//               <i className="fa fa-home"></i>
//               <span className="sr-only">Home</span>
//             </Link>
//           </li>
//           <li className="nav-item">
//             <Link className="nav-link rotatingBorder" to="/attendance">
//               <i className="fa fa-calendar"></i>
//               <span className="sr-only">Attendance</span>
//             </Link>
//           </li>

//           <li className="nav-item">
//             <Link className="nav-link rotatingBorder" to="/about">
//               <i className="fa fa-phone"></i>
//               <span className="sr-only">Contact Us</span>
//             </Link>
//           </li>
//           {!IsloggedIn &&(
//             <li className="nav-item">
//             <Link className="nav-link rotatingBorder" to="/signin">
//               <i className="fa fa-sign-in"></i>
//               <span className="sr-only">Sign In</span>
//             </Link>
//           </li>
//           )}
//           {IsloggedIn &&(
//             <>
//           <li className="nav-item">
//             <Link className="nav-link rotatingBorder" to="/dashboard">
//               <i className="fa fa-user"></i>
//               <span className="sr-only">Dashboard</span>
//             </Link>
//           </li>
//           <li className="nav-item">
//             <Link className="nav-link rotatingBorder" to="/signout">
//               <i className="fa fa-sign-out"></i>
//               <span className="sr-only">Sign Out</span>
//             </Link>
//           </li>
//           </>
//           )}
//         </ul>
//       </nav>
//     </div>
//   );
// };

// export default BottomNavbar;


import React from 'react';
import { Link } from 'react-router-dom';

const BottomNavbar = () => {
    const isLoggedIn = localStorage.getItem('accessToken');

    const navTextStyle = {
        display: 'block',
        fontSize: '0.6rem', // Adjust font size as needed
        color: '#fff', // Change color if needed
        marginTop: '0.2rem' // Adjust spacing as needed
    };

    return (
        <div className="container-fluid">
            <nav className="navbar fixed-bottom navbar-dark bg-dark d-block d-lg-none p-1">
                <ul className="navbar-nav ml-auto mr-auto d-flex flex-row justify-content-between" style={{ width: '100%' }}>
                    <li className="nav-item">
                        <Link className="nav-link text-center rotatingBorder" to="/">
                            <i className="fa fa-home"></i>
                            <span style={navTextStyle}>Home</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link text-center rotatingBorder" to="/attendance">
                            <i className="fa fa-calendar"></i>
                            <span style={navTextStyle}>Attendance</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link text-center rotatingBorder" to="/about">
                            <i className="fa fa-phone"></i>
                            <span style={navTextStyle}>Contact Us</span>
                        </Link>
                    </li>
                    {!isLoggedIn && (
                        <li className="nav-item">
                            <Link className="nav-link text-center rotatingBorder" to="/signin">
                                <i className="fa fa-sign-in"></i>
                                <span style={navTextStyle}>Sign In</span>
                            </Link>
                        </li>
                    )}
                    {isLoggedIn && (
                        <>
                            <li className="nav-item">
                                <Link className="nav-link text-center rotatingBorder" to="/dashboard">
                                    <i className="fa fa-user"></i>
                                    <span style={navTextStyle}>Dashboard</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-center rotatingBorder" to="/signout">
                                    <i className="fa fa-sign-out"></i>
                                    <span style={navTextStyle}>Sign Out</span>
                                </Link>
                            </li>
                        </>
                    )}
                </ul>
            </nav>
        </div>
    );
};

export default BottomNavbar;
