import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { QRCodeCanvas } from 'qrcode.react';
import { BASE_URL } from './Context';
import ButtonLoader from './ButtonLoader';
import { useParams } from 'react-router-dom';

const Payment = () => {
    const { seatBookingId } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [paymentDetails, setPaymentDetails] = useState(null);

    useEffect(() => {
        axios.get(`${BASE_URL}/payment/payment-details/${seatBookingId}`)
            .then(response => {
                setPaymentDetails(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching payment details:', error);
                setError('Failed to fetch payment details');
                setLoading(false);
            });
    }, []);


    // Construct the UPI URL for the QR code if paymentDetails is available
    const upiData = paymentDetails ? `UPI://pay?pa=${paymentDetails.payee_vpa}&pn=${paymentDetails.library}&am=${paymentDetails.amount}&cu=${paymentDetails.currency}&tn=${paymentDetails.message}` : '';

    // Inline styles
    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        border: '1px solid #ddd',
        backgroundColor: '#fff',
        borderRadius: '8px',
        maxWidth: '400px',
        margin: 'auto',
    };


    const upiIdStyle = {
        fontSize: '18px',
        fontWeight: 'bold',
        margin: '20px 0',
    };

    const qrCodeStyle = {
        marginTop: '20px',
    };

    // Render loading state, error message, or the actual content
    if (loading) return <div className='text-center'><ButtonLoader loading={loading} text='Redirecting to payment Page. Do not refresh page or press back button..'/></div>;
    if (error) return <div>{error}</div>;

    return (
        <div style={containerStyle}>
            {paymentDetails && (
            <>
            <h5>{paymentDetails.library}</h5>
            <small>{paymentDetails.library} Payment</small>
            <div style={upiIdStyle}>
                <strong>UPI ID:</strong> {paymentDetails.payee_vpa}
            </div>
            <div>
                <p>
                    {paymentDetails.message}
                </p>
            </div>

                <div style={qrCodeStyle}>
                    <QRCodeCanvas value={upiData} size={256} />
                </div>
            </>
            )}
        </div>
    );
};

export default Payment;

