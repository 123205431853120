import React, { useState, useEffect } from 'react';
import { QrReader } from 'react-qr-reader';
import axios from 'axios';
import { BASE_URL } from './Context';
import { Link, useNavigate } from 'react-router-dom';

const QRCodeScanner = () => {
    const [isScanning, setIsScanning] = useState(false);
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Check if access token exists in local storage
        const accessToken = localStorage.getItem('accessToken');
        setIsLoggedIn(!!accessToken);

        // Add keyframes for scan animation
        const style = document.createElement('style');
        style.innerHTML =
            `@keyframes scan {
                0% { top: 0; }
                50% { top: 100%; }
                100% { top: 0; }
            }`;
        document.head.appendChild(style);
    }, []);

    const handleScan = async (result) => {
        if (result?.text) {
            try {
                const response = await axios.post(`${BASE_URL}/api/v1/attendance/`, {
                    scanned_code: result.text,
                });
                setMessage(response.data.message || 'Attendance marked successfully.');
                navigate('/attendance/');
            } catch (err) {
                setError(err.response?.data?.message || 'Failed to mark attendance.');
            }
            setIsScanning(false);
        }
    };

    const handleError = (err) => {
        setError('Error reading QR code.');
        setIsScanning(false);
    };

    const startScanning = () => {
        setIsScanning(true);
    };

    const stopScanning = () => {
        setIsScanning(false);
    };

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#f5f5f5',
    };

    const boxStyle = {
        width: '300px',
        height: '300px',
        border: '2px solid #000',
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: '#fff',
        marginBottom: '20px',
    };

    const lineStyle = {
        width: '100%',
        height: '4px',
        backgroundColor: 'red',
        position: 'absolute',
        top: '100px',
        animation: isScanning ? 'scan 2s infinite' : 'none',
    };

    return (
        <div style={containerStyle}>
            <div style={boxStyle}>
                <div style={lineStyle}></div>
                {isLoggedIn && isScanning && (
                    <QrReader
                        onResult={handleScan}
                        onError={handleError}
                        style={{ width: '100%' }}
                        constraints={{
                            facingMode: { exact: "environment" },
                        }}
                    />
                )}
            </div>
            {isLoggedIn ? (
                <>
                    {isScanning ? (
                        <button className='btn btn-danger' onClick={stopScanning}>Stop Scanning</button>
                    ) : (
                        <button className='btn btn-primary' onClick={startScanning}>Start Scanning</button>
                    )}
                    {error && <p>{error}</p>}
                    {message && <p>{message}</p>}
                </>
            ) : (
                <>
                    <p>Please sign in to Mark Attendance.</p>
                    <Link to="/signin">
                        <button className='btn btn-primary'>Sign In</button>
                    </Link>
                </>
            )}
            <p className="" style={{ color: 'black' }}>Scan QR to mark your Entry/Exit.</p>
        </div>
    );
};

export default QRCodeScanner;
