import React from 'react';
import Navbar from './Navbar';
import './about.css';
import './bouncer.css';
import Footer from './Footer';
import { CDN_URL, LIBRARY_NAME } from './Context';
import BottomNavbar from './BottomNavbar';
import Team from './Team';

export default function About() {
    return (
        <div className="">
            <Navbar />
            <Team/>
            <div className="">

                <div className="bg-light text-center">
                    <div className="container py-3">
                        <div className="row h-100 align-items-center py-3">
                            <div className="col-lg-12">
                                <h1 className="display-4">About us</h1>
                                <p className="lead text-muted mb-0">
                                    Everything you want to know about {LIBRARY_NAME}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-white py-3">
                    <div className="container py-3">

                        <div className="row align-items-center mb-5">
                            <div className="col-lg-8 order-1 order-lg-1">
                                <div className="text-center">
                                    <i className="fa fa-bar-chart fa-2x mb-3 order-1 text-primary"></i>
                                </div>
                                <h2 className="font-weight-light text-primary text-center">Our Vision</h2>
                                <p className="font-italic text-muted mb-4">
                                    At {LIBRARY_NAME}, our vision is to create an inspiring and accessible space where students and individuals can thrive academically and personally. We aim to provide a tranquil, well-equipped environment that fosters learning and intellectual growth. By offering affordable, high-quality study facilities and continuously enhancing our services, we aspire to be the premier destination for focused study and personal development in our community.
                                </p>
                            </div>
                            <div className="col-lg-4 px-5 mx-auto order-2 order-lg-2">
                                <img src={(`${CDN_URL}/Images/vision.png`)} alt="" className="img-fluid mb-4 mb-lg-0" />
                            </div>
                        </div>
                    <div className="text-center">
                      <i className="fa fa-leaf fa-2x mb-3 order-1 text-primary"></i>
                    </div>
                    <h2 className="font-weight-light text-center text-primary">How We Function</h2>
                    <p>
                      <strong>At {LIBRARY_NAME}, we are dedicated to providing a seamless and efficient experience for all our patrons. Here’s how we function to ensure your time with us is productive and enjoyable:</strong>
                    </p>
                        {/* Reservation System */}
                        <div className="row align-items-center mb-5">
                            <div className="col-lg-4 px-5 mx-auto order-1 order-lg-2">
                                <img src={(`${CDN_URL}/Images/reserved.png`)} alt="" className="img-fluid mb-4 mb-lg-0" />
                            </div>
                            <div className="col-lg-8 order-2 order-lg-1">
                                <h3 className="font-weight-light text-primary">Reservation System</h3>
                                <ul>
                                    <li><strong>Easy Booking:</strong> Reserve your study space through our user-friendly online reservation system or by contacting our front desk. Select your preferred date and time, and receive instant confirmation.</li>
                                    <li><strong>Flexible Options:</strong> Choose from a range of time slots to suit your schedule. Our system allows you to modify or cancel your reservation with ease, ensuring flexibility.</li>
                                </ul>
                            </div>
                        </div>

                        {/* Welcoming Environment */}
                        <div className="row align-items-center mb-5">
                            <div className="col-lg-8 order-1 order-lg-1">
                                <h3 className="font-weight-light text-primary">Welcoming Environment</h3>
                                <ul>
                                    <li><strong>Comfortable Facilities:</strong> Enjoy a quiet and comfortable environment with amenities such as air conditioning, fans, and adjustable lighting. Our spaces are designed to provide optimal conditions for focused study and relaxation.</li>
                                    <li><strong>Clean and Safe:</strong> We maintain high standards of cleanliness and safety, regularly sanitizing our facilities to ensure a healthy environment for all users.</li>
                                </ul>
                            </div>
                            <div className="col-lg-4 px-5 mx-auto order-2 order-lg-2">
                                <img src={(`${CDN_URL}/Images/vision.png`)} alt="" className="img-fluid mb-4 mb-lg-0" />
                            </div>
                        </div>

                        {/* Support and Assistance */}
                        <div className="row align-items-center mb-5">
                            <div className="col-lg-4 px-5 mx-auto order-1 order-lg-2">
                                <img src={(`${CDN_URL}/Images/support.png`)} alt="" className="img-fluid mb-4 mb-lg-0" />
                            </div>
                            <div className="col-lg-8 order-2 order-lg-1">
                                <h3 className="font-weight-light text-primary">Support and Assistance</h3>
                                <ul>
                                    <li><strong>Friendly Staff:</strong> Our knowledgeable staff are available to assist you with any questions or needs you may have during your visit. Whether you need help with your reservation or have specific requirements, we’re here to support you.</li>
                                    <li><strong>Feedback and Improvement:</strong> We value your feedback and continuously strive to improve our services based on your suggestions and needs.</li>
                                </ul>
                            </div>
                        </div>

                        {/* Affordable Pricing */}
                        <div className="row align-items-center mb-5">
                            <div className="col-lg-8 order-1 order-lg-1">
                                <h3 className="font-weight-light text-primary">Affordable Pricing</h3>
                                <ul>
                                    <li><strong>Minimal Costs:</strong> We offer our services at competitive and minimal prices, ensuring that high-quality study spaces are accessible to all students and individuals.</li>
                                    <li><strong>Transparent Rates:</strong> Our pricing is straightforward with no hidden fees. Check our website or contact us for detailed information on costs.</li>
                                </ul>
                            </div>
                            <div className="col-lg-4 px-5 mx-auto order-2 order-lg-2">
                                <img src={(`${CDN_URL}/Images/price.png`)} alt="" className="img-fluid mb-4 mb-lg-0" />
                            </div>
                        </div>

                        {/* User Authentication */}
                        <div className="row align-items-center mb-5">
                            <div className="col-lg-4 px-5 mx-auto order-1 order-lg-2">
                                <img src={(`${CDN_URL}/Images/auth.png`)} alt="" className="img-fluid mb-4 mb-lg-0" />
                            </div>
                            <div className="col-lg-8 order-2 order-lg-1">
                                <h3 className="font-weight-light text-primary">User Authentication</h3>
                                <ul>
                                    <li><strong>Secure Access:</strong> For your convenience, access to some features may  user authentication. Our secure login system ensures that your reservations and personal information are protected.</li>
                                </ul>
                            </div>
                        </div>

                        {/* Community Focus */}
                        <div className="row align-items-center mb-5">
                            <div className="col-lg-8 order-1 order-lg-1">
                                <h3 className="font-weight-light text-primary">Community Focus</h3>
                                <ul>
                                    <li><strong>Events and Workshops:</strong> We occasionally host events and workshops aimed at enhancing the academic and personal growth of our users. Stay updated on upcoming events through our website and social media channels.</li>
                                </ul>
                            </div>
                            <div className="col-lg-4 px-5 mx-auto order-2 order-lg-2">
                                <img src={(`${CDN_URL}/Images/community.png`)} alt="" className="img-fluid mb-4 mb-lg-0" />
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <BottomNavbar/>
            <Footer />
        </div>
    )
}
