import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './axiosConfig';  // Import the axiosConfig file to set up the interceptor

// Set global variables from environment variables
window.libraryName = process.env.REACT_APP_LIBRARY_NAME || 'Default Library Name';
window.gTag = process.env.REACT_APP_G_TAG || 'Default-G-Tag';

ReactDOM.render(
  <div id="app" className="loader">
  <React.StrictMode>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
  </React.StrictMode>
  </div>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();