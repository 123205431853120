import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import Footer from './Footer'
import Navbar from './Navbar'
import './event.css';
import { BASE_URL } from './Context';

export default function Event({ match }) {
    const { eName } = useParams()
    const [event, setEvent] = useState({})
    const [notifyEmail, setNotifyEmail] = useState();


    // console.log(notifyEmail)
    const Navigate = useNavigate();
    useEffect(() => {
        axios.get(`${BASE_URL}/api/event/${eName}`)
            .then(res => setEvent(res.data))
            .catch(err => {
                console.log(err, 'Event not found react')
            })
    }, [eName])

    const onSubmitHandler = e =>{
        e.preventDefault();
        axios.post(`${BASE_URL}/api/notify/${eName}/${notifyEmail}`)
        .then(()=> alert('Event Scheduled'))
        .catch(()=> alert('Some Error Occured'))
    }

    // console.log(event)

    if (!event) Navigate("*")
    var video = 'https://www.youtube.com/embed/';
    video += event.evideoid ? event.evideoid.split('=')[1] : 'SpMVPPIl8x0';
    return (
        <div>
            <Navbar />
            {event ? (
                <>
                    <div className="container my-5" >
                        <div className='row'>
                            <div className='col-lg-4'>
                                <div className="list-container mb-3">

                                <div id="list">
                                    <h3>{event.eTitle}</h3>
                                    <div id="list-item">
                                        <div id="name">
                                            <h4> Event Name</h4>
                                            <p>{event.eName}</p>
                                        </div>
                                    </div>
                                    <div id="list-item">
                                        <div id="name">
                                            <h4>Date</h4>
                                            <p>{event.eDate}</p>
                                        </div>
                                    </div>
                                    <div id="list-item">
                                        <div id="name">
                                            <h4>Event Timing</h4>
                                            <p>{event.eTime}</p>
                                        </div>
                                    </div>

                                    <div id="list-item">
                                        <div id="name">
                                            <h4>Venue</h4>
                                            <p>{event.eVenue} {event.eCountry}</p>
                                        </div>
                                    </div>
                                    <div id="list-item">
                                        <div id="name">
                                            <h4>Contact Email:</h4>
                                            <p>{event.email}</p>
                                        </div>
                                    </div>
                                    <div id="list-item">
                                        <div id="name">
                                            <h4>Contact Number</h4>
                                            <p>{event.code}{event.mobNo}</p>
                                        </div>
                                    </div>

                                    <form onSubmit={onSubmitHandler}>
                                        <input class="form-control form-control-sm" type="email" placeholder="email@domain.com" aria-label=".form-control-sm example" onChange={e => setNotifyEmail(e.target.value)} required></input>
                                        <hr />
                                        <button type='submit' className='btn btn-warning'>Notify</button>
                                    </form>

                                </div>
                                </div>
                            </div>
                            <div className='col-lg-8'>
                            <div class="profilecard video-container">
                                <div className='profilecard-body'>
                                    <iframe
                                            src={`${video}`}
                                            frameborder="0"
                                            allow="autoplay; encrypted-media"
                                            allowfullscreen="true"
                                            title="video"></iframe>
                                </div>
                            </div>
                            </div>
                        </div>

                    </div>


                </>
            ) : null}


            {event ? (
                <div className="mt-5 text-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="eventcard">
                                    <div className="eventcard-body">
                                        <h3 className="eventcard-title bg-info mb-5">Vertical Timeline</h3>

                                        <div className="row justify-content-center">
                                            <div className="col-lg-10">
                                                <ul className="verti-timeline list-unstyled" dir="ltr">

                                                    <li className="event-list">
                                                        <div className="timeline-icon">
                                                            <i className="mdi mdi-adjust bg-warning"></i>
                                                        </div>
                                                        <div className="event-content">
                                                            <h2 className="mt-0 mb-3 font-18">{event.eName}</h2>
                                                            <p style={{float: "right"}}>{event.eTitle}</p>
                                                            <div className="text-muted">
                                                                <div style={{ color: "black", wordWrap: 'break-word', textAlign: 'justify' }} className="mb-0" dangerouslySetInnerHTML={{ __html: event.eBody }}></div>
                                                            </div>

                                                        </div>
                                                        <div className="event-date bg-primary">
                                                            {/* <h5 className="mt-0">{event.eDate.slice(9,11)}</h5> */}
                                                            <p className="mb-0 text-white-50">{event.eDate}</p>
                                                            <p className="mb-0 text-white-50">{event.eTime}</p>
                                                        </div>

                                                    </li>

                                                </ul>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>) : null}

            <Footer />
        </div>
    )
}
