import React from 'react';
import Navbar from './Navbar';
import Intro from './Intro';
import FAQ from './FAQ';
import Footer from './Footer';
import Eschd from './EventScheduler';
import Extra from './Extra';
import Venue from './Venue';
import Gallery from './Gallery';
// import Offer from './Offer';
import ShiftScheduler from './ShiftScheduler';
import OfferList from './OfferList';
import BottomNavbar from './BottomNavbar';

function Home() {
  return (
    <div>
        <Navbar/>
        <Intro/>
        <Extra/>
        {/* <Offer/> */}
        <Eschd />
        <OfferList/>
        <ShiftScheduler/>
        <FAQ/>
        <Gallery/>
        <Venue/>
        <BottomNavbar/>
        <Footer/>
    </div>
  )
}

export default Home