import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import axios from 'axios';
import './signin.css';
import Form from './Form';
import Navbar from './Navbar';
import { BASE_URL } from './Context';
import GoogleSignInButton from './GoogleSignInButton';
import ButtonLoader from './ButtonLoader';

class SignIn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      token: '',
      redirect: localStorage.getItem('accessToken') && localStorage.getItem('refreshToken') ? true : false,
      requestError: '',
      loading: false,  // Initialize loading state
    }
  }

  onSubmitHandler = (e) => {
    e.preventDefault();

    if (this.state.email && this.state.password &&
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.state.email)) {

      this.setState({ loading: true });  // Start loading

      axios.post(`${BASE_URL}/accounts/login/`, {
        email: this.state.email,
        password: this.state.password
      }).then(res => {
        this.setState({ loading: false });  // Stop loading

        // Ensure token is present in the response
        if (res.data.tokens) {
          localStorage.setItem('accessToken', res.data.tokens.access);
          localStorage.setItem('refreshToken', res.data.tokens.refresh);
          this.setState({ redirect: true });
        } else {
          console.error("Token not found in response:", res);
          this.setState({ requestError: "Login failed. It seems you Tried Signup before but not verified you email, Check you Email inbox also check Spam/Junk and mark is as safe." });
        }
      }).catch(err => {
        this.setState({ loading: false });  // Stop loading

        console.error("Login error:", err); // Log the error for debugging
        if (err.response) {
          if (err.response.status === 403) {
            this.setState({
              requestError: "Please confirm verification sent to your email address. Check Spam/Junk also and mark it as safe."
            });
          } else {
            this.setState({
              requestError: "Either Username or Password or both is invalid."
            });
          }
        } else {
          this.setState({ requestError: "An unknown error occurred. Please try again later." });
        }
      });
    } else {
      this.setState({ requestError: "Please fill all the details" });
    }
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value, requestError: '' });
  }

  render() {
    if (this.state.redirect) return <Navigate to="/" />;
    return (
      <React.Fragment>
        <Navbar />
        <div>
          <div className="container">
            <div className="d-flex justify-content-between align-items-end my-2 text-center text-warning">
                {this.state.requestError && <p>{this.state.requestError}</p>}
            </div>
            <div className="d-flex justify-content-center h-100">
              <div className="signincard">
                <div className="card-header">
                  <h3 className="text-center text-info">Login</h3>
                </div>
                <div className="card-body">
                  <Form onSubmit={this.onSubmitHandler}>
                    <div className="form-group">
                      <label htmlFor="email" className="text-info">Email:</label><br />
                      <input
                        id="email"
                        className="form-control"
                        type="email"
                        name="email"
                        placeholder="xyz@domain.com"
                        onChange={this.changeHandler}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="password" className="text-info">Password:</label><br />
                      <input
                        id="password"
                        className="form-control"
                        type="password"
                        name="password"
                        placeholder="********"
                        onChange={this.changeHandler}
                        required
                      />
                    </div>
                    <div className="form-group text-center my-2 mb-0">
                      <button
                        type="submit"
                        className="btn float-right login_btn transonclick"
                        disabled={this.state.loading}  // Disable button when loading
                        style={{ position: 'relative' }} // Ensure relative positioning
                      >
                        {/* {this.state.loading ? '' : 'Login'} */}
                        <ButtonLoader loading={this.state.loading} text="Login" />
                      </button>
                    </div>
                  </Form>
                  <div className="card-footer" >
                    <div className="mt-2 d-flex justify-content-center links transonclick">
                      <Link style={{ textDecoration: 'none', color: "#ffff" }} to="/signup" className="d-flex justify-content-centertext-info">New User?? SignUp</Link>
                    </div>
                    <div className="mt-1 d-flex justify-content-center transonclick">
                      <Link style={{ textDecoration: 'none', color: "#ffff" }} to="/password/forgot">Forgot your password?</Link>
                    </div>
                    <GoogleSignInButton/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SignIn;
