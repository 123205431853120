import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { decodeToken } from 'react-jwt'
// import { Link } from 'react-router-dom'
import Footer from './Footer'
import Navbar from './Navbar'
import { NavLink } from 'react-router-dom';
import './dashboard.css';
import { BASE_URL } from './Context';
import SeatBookings from './Seatbookings';
// import QRCode from './QRCode'
// import QRCodeScanner from './QRCodeScanner'
import BottomNavbar from './BottomNavbar'
import GenerateQR from './GenerateQR'
import UserLibrary from './UserLibrary'

function Dashboard() {
    const token = localStorage.getItem("accessToken")
    const decode = decodeToken(token)
    console.log("Decoded-",decode);

    const [profile, setProfile] =useState({});
    const [userprofile, setUserProfile] =useState({});
    // const [userprofiles, setUserProfiles] =useState({});
    const [userlibraryrole, setuserlibraryrole] =useState({});
    const [loadProfiles, setLoadProfiles] = useState(false); // New state to manage profile loading

    useEffect(()=>{
        axios.get(`${BASE_URL}/api/v1/userlibraries/`)
        .then(res =>{
            if (res.data.results && res.data.results.length > 0) {
                setuserlibraryrole(res.data.results[0]);
            } else {
                setuserlibraryrole('user');
            }
        })

        .catch(err => console.log(err.message))
    },[])
    useEffect(()=>{
        axios.get(`${BASE_URL}/accounts/profile/${decode.user_id}`)
        .then(res => setProfile(res.data))
        .catch(err => console.log(err.message))
    },[decode.user_id])
    useEffect(()=>{
        axios.get(`${BASE_URL}/api/v1/userprofiles/${decode.user_id}/`)
        .then(res => setUserProfile(res.data))
        .catch(err => console.log(err.message))
    },[decode.user_id])


    console.log("Profile-",profile)
    console.log("userProfile-",userprofile)
    console.log('userlibrary role==',userlibraryrole)


    // Toggle to load profiles when button is pressed
    const handleLoadProfiles = () => {
        setLoadProfiles(true); // Update the state to true to load profiles
    };

    return (
        <div className='bg-light'>
            <Navbar />
            <h2 className="mx-1 my-1 text-center p-2" style={{ color: "black" }}>Hi <strong style={{ color: "red" }}>{profile.first_name}</strong></h2>

            <div className="container bg-light">
                <div className="main-body">

                    <div className="row gutters-sm">
                        <div className="col-md-4 mb-3">
                            <div className="border p-4">
                                <div className="">
                                    <div className="d-flex flex-column align-items-center text-center">
                                        <img src={"https://bootdey.com/img/Content/avatar/avatar7.png"} alt="Admin" className="rounded-circle" width={"150"} />
                                        <div className="mt-3">
                                            <h4 className='text-info' style={{ textDecoration: 'none', "textTransform": "uppercase" }}>{profile.username}</h4>
                                            <p className="mb-1 text-light">{profile.bio}</p>

                                            <NavLink className="btn transonclick btn-info" to="/password/forgot"><i className='bi bi-pencil-square'></i> Change Password</NavLink>
                                            <div className="text-center">
                                                <div className="mt-3">
                                                    {userlibraryrole.role==="Library_Admin"?
                                                    <NavLink className="nav-item btn btn-primary" to="/create-holiday">ADD Holidays</NavLink>
                                                    :''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-3">
                                <div className="profilecard-body">
                                    <ul className="list-group list-group-flush text-center">
                                        <li className="list-group-item bg-transparent">
                                            <a href={`${userprofile.twitter}`} style={{ textDecoration: 'none'}} target= '_blank' rel="noreferrer" >
                                            <h6 className="mb-0" style={{ color: "skyblue" }}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mx-1 feather feather-twitter mr-2 icon-inline text-info"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
                                            </svg>Twitter</h6>
                                            </a>
                                        </li>
                                        <li className="list-group-item bg-transparent">
                                            <a href={`${userprofile.instagram}`} style={{ textDecoration: 'none'}} target= '_blank' rel="noreferrer" >
                                            <h6 className="mb-0" style={{ color: "red" }}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mx-1 feather feather-instagram mr-2 icon-inline text-danger"><rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
                                            </svg>Instagram</h6>
                                            </a>
                                        </li>
                                        <li className="list-group-item bg-transparent">
                                            <a href={`${userprofile.facebook}`} style={{ textDecoration: 'none'}} target= '_blank' rel="noreferrer" >
                                            <h6 className="mb-0" style={{ color: "blue" }}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mx-1 feather feather-facebook mr-2 icon-inline text-primary"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
                                            </svg>Facebook</h6>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* <div className='text-center mt-3'>
                                <QRCode/>
                                <p clasname="" style={{color:'black'}}> Scan QR to mark your Entry/Exit.</p>
                            </div>
                            <div className='text-center mt-3'>
                                <h3>Scan QR Code</h3>
                                <QRCodeScanner />
                            </div> */}

                            {userlibraryrole && userlibraryrole.role === "Library_Admin" && (
                                <>
                                <GenerateQR/>
                                </>
                            )}
                        </div>


                        <div className="col-md-8">

                        <div className='border p-4' style={{ width: '100%' }}>
                            <table style={{ width: '100%', tableLayout: 'fixed', borderCollapse: 'collapse' }}>
                                <tbody>
                                    <tr>
                                        <th style={{ textAlign: 'left', width: '35%', padding: '8px' }}>Full Name:</th>
                                        <td style={{ width: '60%', padding: '8px', wordWrap: 'break-word', overflowWrap: 'break-word' }}>
                                            {profile.first_name} {profile.last_name}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th style={{ textAlign: 'left', width: '35%', padding: '8px' }}>Email:</th>
                                        <td style={{ width: '60%', padding: '8px', wordWrap: 'break-word', overflowWrap: 'break-word' }}>
                                            {profile.email}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th style={{ textAlign: 'left', width: '35%', padding: '8px' }}>Mobile No:</th>
                                        <td style={{ width: '60%', padding: '8px', wordWrap: 'break-word', overflowWrap: 'break-word' }}>
                                            <a href={`tel:${userprofile.mobile_number}`} style={{ color: 'blue', textDecoration: 'none' }}>
                                                {userprofile.mobile_number}
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th style={{ textAlign: 'left', width: '35%', padding: '8px' }}>Address:</th>
                                        <td style={{ width: '60%', padding: '8px', wordWrap: 'break-word', overflowWrap: 'break-word' }}>
                                            {userprofile.address}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="row">
                                <div className="col-sm-12">
                                    <NavLink className="btn transonclick btn-info" to="/updateprofile"><i className='bi bi-pencil-square'></i> Edit</NavLink>
                                </div>
                            </div>
                        </div>
                        <hr />

                        <div className="mt-5 mb-3">
                            <div className="">
                                <div className="row">
                                    <div className="col-sm">
                                        <h4 className="mb-0" style={{ textDecoration: 'none', color: "black" }}><strong>ALLOTED SEATS</strong></h4>
                                    </div>
                                </div>
                                <hr />
                                <SeatBookings userrole={userlibraryrole}/>

                            </div>
                        </div>
                            <hr />
                        <div className="border rounded mt-5 mb-3">
                            {userlibraryrole && userlibraryrole.role === "Library_Admin" &&
                                <>
                                <button
                                    className="btn btn-info mb-3"
                                    onClick={handleLoadProfiles}>
                                    Load Library User's Profile
                                </button>
                                {loadProfiles && <UserLibrary/>}
                                </>
                            }
                        </div>
                        </div>

                    </div>

                </div>
            </div>
            <BottomNavbar/>
            <Footer />
        </div>
    )
}

export default Dashboard
