// import React, { useState, useEffect, useRef } from 'react';
// import axios from 'axios';
// import QRCodeScanner from './QRCodeScanner';
// import QRCode from './QRCode';
// import Footer from './Footer';
// import Navbar from './Navbar';
// import BottomNavbar from './BottomNavbar';
// import { BASE_URL } from './Context';
// import { decodeToken } from 'react-jwt';
// import { useReactToPrint } from 'react-to-print';

// function Attendance(props) {
//     const [attendance, setAttendance] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState('');

//     // Use ref to get reference to the component for printing
//     const componentRef = useRef(null);
//     const handlePrint = useReactToPrint({
//         content: () => componentRef.current,
//     });

//     useEffect(() => {
//         const token = localStorage.getItem("accessToken");

//         if (!token) {
//             setError('No access token found.');
//             setLoading(false);
//             return;
//         }

//         try {
//             const decode = decodeToken(token);
//             const userId = decode.user_id;

//             const fetchAttendance = async () => {
//                 try {
//                     const response = await axios.get(`${BASE_URL}/api/v1/attendance/`);
//                     setAttendance(response.data.results || []);
//                     setLoading(false);
//                 } catch (err) {
//                     setError('Failed to fetch attendance data.');
//                     setLoading(false);
//                 }
//             };

//             fetchAttendance();
//         } catch (err) {
//             setError('Failed to decode token.');
//             setLoading(false);
//         }
//     }, []);

//     return (
//         <div>
//             <Navbar />
//             <div className='text-center mt-3'>
//                 <QRCode />
//                 <p className="" style={{ color: 'white' }}>Scan QR to mark your Entry/Exit.</p>
//             </div>
//             <div className='text-center mt-3 mb-3'>
//                 <h3>Scan QR Code</h3>
//                 <QRCodeScanner />
//             </div>
//             {loading && <p>Loading attendance data...</p>}
//             {error && <p>{error}</p>}
//             {attendance.length > 0 && (
//                 <div className='text-center'>
//                     <h3>Attendance Information</h3>
//                     {/* Reference to the part of the component we want to print */}
//                     <div ref={componentRef} style={{ overflowX: 'auto' }}>
//                         <table className="table table-bordered" style={{ minWidth: '600px' }}>
//                             <thead className="bg-gradient bg-info">
//                                 <tr>
//                                     <th>UserID</th>
//                                     <th>FullName</th>
//                                     <th>Date</th>
//                                     <th>Status</th>
//                                     <th>Entry Time</th>
//                                     <th>Exit Time</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {attendance.map((record) => (
//                                     <tr key={record.id}>
//                                         <td>{record.user}</td>
//                                         <td>{record.fullname}</td>
//                                         <td>{record.date}</td>
//                                         <td>{record.status}</td>
//                                         <td>{new Date(record.entry_time).toLocaleTimeString()}</td>
//                                         <td>{record.exit_time ? new Date(record.exit_time).toLocaleString() : 'Not yet exited'}</td>
//                                     </tr>
//                                 ))}
//                             </tbody>
//                         </table>
//                     </div>
//                     {/* Print button */}
//                     <button onClick={handlePrint} className="btn btn-primary mt-3">
//                         Print Attendance
//                     </button>
//                 </div>
//             )}
//             <BottomNavbar />
//             <Footer />
//         </div>
//     );
// }

// export default Attendance;



import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import QRCodeScanner from './QRCodeScanner';
import Footer from './Footer';
import Navbar from './Navbar';
import BottomNavbar from './BottomNavbar';
import { BASE_URL } from './Context';
import { decodeToken } from 'react-jwt';
import { useReactToPrint } from 'react-to-print';
import ButtonLoader from './ButtonLoader';

function Attendance(props) {
    const [attendance, setAttendance] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [date, setDate] = useState(new Date().toISOString().split('T')[0]); // Default to today's date
    const [month, setMonth] = useState(new Date().toISOString().slice(0, 7)); // Default to current month
    const [startDate, setStartDate] = useState(''); // Default empty
    const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]); // Default to today's date
    const [filterType, setFilterType] = useState('date'); // Default filter by date

    // Use ref to get reference to the component for printing
    const componentRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(() => {
        // Set today's date and current month as default values
        const today = new Date().toISOString().split('T')[0];
        const currentMonth = new Date().toISOString().slice(0, 7); // Format: YYYY-MM
        setDate(today);
        setMonth(currentMonth);
        setStartDate(today);
        setEndDate(today);
    }, []);

    useEffect(() => {
        const token = localStorage.getItem("accessToken");

        if (!token) {
            setError('No access token found.');
            setLoading(false);
            return;
        }

        try {
            const decode = decodeToken(token);
            const userId = decode.user_id;

            const fetchAttendance = async () => {
                try {
                    // Construct query parameters based on filters
                    const params = new URLSearchParams();
                    if (filterType === 'date' && date) {
                        params.append('date', date);
                    } else if (filterType === 'month' && month) {
                        params.append('month', month);
                    } else if (filterType === 'range' && startDate && endDate) {
                        params.append('start_date', startDate);
                        params.append('end_date', endDate);
                    }

                    // Only make the request if valid parameters are provided
                    if (params.toString()) {
                        const response = await axios.get(`${BASE_URL}/api/v1/attendance/?${params.toString()}`);
                        console.log(`${BASE_URL}/api/v1/attendance/?${params.toString()}`);
                        setAttendance(response.data.results || []);
                    }
                    setLoading(false);
                } catch (err) {
                    setError('Failed to fetch attendance data.');
                    setLoading(false);
                }
            };

            fetchAttendance();
        } catch (err) {
            setError('Failed to decode token.');
            setLoading(false);
        }
    }, [date, month, startDate, endDate, filterType]); // Include filterType and filters as dependencies

    return (
        <div>
            <Navbar />
            <div className='text-center'>
                <QRCodeScanner />
            </div>
            <div className='container mt-3'>
            <h4 className='text-center'>Filter Attendance Data</h4>
            <div className='row'>
                <div className='col-md-6 offset-md-3'>
                    <table className='table'>
                        <tbody>
                            <tr>
                                <td>
                                    <label htmlFor='filterType' className='form-label'><b>Filter By:</b></label>
                                </td>
                                <td>
                                    <select
                                        id='filterType'
                                        className='form-select'
                                        value={filterType}
                                        onChange={(e) => setFilterType(e.target.value)}
                                    >
                                        <option value='date'>By Date</option>
                                        <option value='month'>By Month</option>
                                        <option value='range'>By Range</option>
                                    </select>
                                </td>
                            </tr>

                            {filterType === 'date' && (
                                <tr>
                                    <td><label htmlFor='date' className='form-label'>Select Date:</label></td>
                                    <td>
                                        <input
                                            id='date'
                                            type='date'
                                            className='form-control'
                                            value={date}
                                            onChange={(e) => setDate(e.target.value)}
                                        />
                                    </td>
                                </tr>
                            )}

                            {filterType === 'month' && (
                                <tr>
                                    <td><label htmlFor='month' className='form-label'>Select Month:</label></td>
                                    <td>
                                        <input
                                            id='month'
                                            type='month'
                                            className='form-control'
                                            value={month}
                                            onChange={(e) => setMonth(e.target.value)}
                                        />
                                    </td>
                                </tr>
                            )}

                            {filterType === 'range' && (
                                <>
                                    <tr>
                                        <td><label htmlFor='startDate' className='form-label'>Start Date:</label></td>
                                        <td>
                                            <input
                                                id='startDate'
                                                type='date'
                                                className='form-control'
                                                value={startDate}
                                                onChange={(e) => setStartDate(e.target.value)}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><label htmlFor='endDate' className='form-label'>End Date:</label></td>
                                        <td>
                                            <input
                                                id='endDate'
                                                type='date'
                                                className='form-control'
                                                value={endDate}
                                                onChange={(e) => setEndDate(e.target.value)}
                                            />
                                        </td>
                                    </tr>
                                </>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
            {loading && <p className='text-center'><ButtonLoader loading={loading} text='Loading attendance data...'/> </p>}
            {error && <p>{error}</p>}
            {attendance.length > 0 && (
                <div ref={componentRef} className='text-center'>
                    <h3>Attendance Information</h3>
                    {/* Reference to the part of the component we want to print */}
                    <div style={{ overflowX: 'auto' }}>
                        <table className="table table-bordered" style={{ minWidth: '600px' }}>
                            <thead className="bg-gradient bg-info">
                                <tr>
                                    <th>UserID</th>
                                    <th>FullName</th>
                                    <th>Date</th>
                                    <th>Status</th>
                                    <th>Entry Time</th>
                                    <th>Exit Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {attendance.map((record) => (
                                    <tr key={record.id}>
                                        <td>{record.user}</td>
                                        <td>{record.fullname}</td>
                                        <td>{record.date}</td>
                                        <td>{record.status}</td>
                                        <td>{new Date(record.entry_time).toLocaleTimeString()}</td>
                                        <td>{record.exit_time ? new Date(record.exit_time).toLocaleString() : 'Not yet exited'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* Print button */}
                    <button onClick={handlePrint} className="btn btn-primary mt-3">
                        Print Attendance
                    </button>
                </div>
            )}
            {attendance.length === 0 && (
            <div className='text-center text-black'>
            No Attendance Data available for this Date.<br/>
            Mark today's Attendance
            </div>)}
            <BottomNavbar />
            <Footer />
        </div>
    );
}

export default Attendance;
