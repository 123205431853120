import React, { useState } from 'react'
import { LIBRARY_NAME } from './Context'

export default function FAQ() {
    const [myStyle, setMyStyle] = useState({
        color: "white",
        backgroundColor: "#606d7a"
    })
    const [btntext, setBtnTxt] = useState("Enable Light Mode")

    const toggleStyle = () => {
        if (myStyle.color === "white") {
            setMyStyle({
                color: '#606d7a',
                backgroundColor: 'white'
            })
            setBtnTxt("Enable Dark Mode")
        }
        else {
            setMyStyle({
                color: 'white',
                backgroundColor: '#606d7a'
            })
            setBtnTxt("Enable Light Mode")
        }
    }
    return (
        <div className="container topmargin mb-5" style={myStyle}>

            <section id="FAQ-container">
                <section id="FAQ">
                <div className="container" data-aos="fade-up">
                <div className="section-header">
                    <h2 style={myStyle}>FAQ</h2>
                    <p>If you have any doubt contact us!!</p>
                </div>
                </div>
                </section>
            </section>

            {/* <h3 className="text-center">FAQ</h3> */}

            <div className="accordion" id="accordionExample" style={myStyle}>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button" style={myStyle} type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <strong> What services does {LIBRARY_NAME} offer?</strong>
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" style={myStyle} aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div className="accordion-body lead">
                            <small>{LIBRARY_NAME} provides a comfortable study environment with amenities including air conditioning, fans, adjustable lighting, and quiet spaces designed to enhance your reading and studying experience.</small>
                            </div>
                        </div>
                    </div>


                    <div className="accordion-item" style={myStyle} >
                        <h2 className="accordion-header" id="headingTwo">
                            <button className="accordion-button collapsed" style={myStyle} type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            <strong>How can I reserve a seat at {LIBRARY_NAME}?</strong>
                            </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div className="accordion-body lead">
                            <small>Reservations can be made through our website or by contacting our front desk. Simply select your preferred date and time, and we will ensure a seat is available for you.</small>
                            </div>
                        </div>
                    </div>


                    <div className="accordion-item" style={myStyle}>
                        <h2 className="accordion-header" id="headingThree">
                            <button className="accordion-button collapsed" style={myStyle} type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            <strong>What are the operating hours of {LIBRARY_NAME}?</strong>

                            </button>
                        </h2>
                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div className="accordion-body lead">
                            <small>Our library is open 24/7, Monday through Sunday. We may adjust hours during holidays or special events, so please check our website for updates. We offer full-day access as well as four shifts: 6 AM–2 PM, 2 PM–10 PM, 10 PM–5 AM, and a special shift from 6 AM–10 PM for dedicated students.</small>
                            </div>
                        </div>
                    </div>


                    <div className="accordion-item" style={myStyle}>
                        <h2 className="accordion-header" id="headingFour">
                            <button className="accordion-button collapsed" style={myStyle} type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                            <strong> Is there a minimum age requirement to use the library?</strong>

                            </button>
                        </h2>
                        <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                            <div className="accordion-body lead">
                            <small>Yes, users must be at least 12 years old to reserve a seat. Children under 12 are welcome if accompanied by an adult.</small>
                            </div>
                        </div>
                    </div>


                    <div className="accordion-item" style={myStyle}>
                        <h2 className="accordion-header" id="headingFive">
                            <button className="accordion-button collapsed" style={myStyle} type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                            <strong>How much does it cost to use the library?</strong>
                            </button>
                        </h2>
                        <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                            <div className="accordion-body lead">
                            <small>We offer our services at minimal prices compared to other facilities. For specific pricing information, please visit our website or contact our front desk.</small>
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item" style={myStyle}>
                        <h2 className="accordion-header" id="headingSix">
                            <button className="accordion-button collapsed" style={myStyle} type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                            <strong>Can I cancel or modify my reservation?</strong>

                            </button>
                        </h2>
                        <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                            <div className="accordion-body lead">
                            <small>Yes, reservations can be modified or canceled up to 24 hours before your scheduled time. Please contact us as soon as possible to make any changes.</small>
                            </div>
                        </div>
                    </div>


                    <div className="accordion-item" style={myStyle}>
                        <h2 className="accordion-header" id="headingSeven">
                            <button className="accordion-button collapsed" style={myStyle} type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                            <strong>Do I need to bring my own study materials?</strong>

                            </button>
                        </h2>
                        <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                            <div className="accordion-body lead">
                            <small>Yes, is the simple answer? please bring your own study materials, such as books and notebooks. We provide a comfortable space and amenities but do not supply study materials.</small>
                            </div>
                        </div>
                    </div>


                    <div className="accordion-item" style={myStyle}>
                        <h2 className="accordion-header" id="headingEight">
                            <button className="accordion-button collapsed" style={myStyle} type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                            <strong>Is Wi-Fi available at the library?</strong>
                            </button>
                        </h2>
                        <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                            <div className="accordion-body lead">
                            <small>Yes, we offer free Wi-Fi to all our patrons. Connect to our network using the details provided at the front desk upon arrival.</small>
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item " style={myStyle}>
                        <h2 className="accordion-header  " id="headingTen">
                            <button className="accordion-button collapsed  " style={myStyle} type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                            <strong>What should I do if I have a complaint or suggestion?</strong>
                            </button>
                        </h2>
                        <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample">
                            <div className="accordion-body lead">
                            <small>We welcome your feedback! Please speak to a staff member directly or send us an email through our contact page on the website. Your suggestions help us improve our services.</small>
                            </div>
                        </div>
                    </div>
            </div>

            <div className="container my-3" style={myStyle}>
                <button className="btn transonclick btn-primary" onClick={toggleStyle}>{btntext}</button>
            </div>

            <br/>
        </div>

    )
}
