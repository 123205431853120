import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL } from './Context';
import { Table } from 'react-bootstrap';
import ButtonLoader from './ButtonLoader';

const UserLibrary = () => {
  const [userLibraries, setUserLibraries] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get(`${BASE_URL}/api/v1/profiles/`)
      .then(response => {
        setUserLibraries(response.data.results);
        console.log("userlibrary profiles response==", response.data.results);
        setLoading(false);
      })
      .catch(error => {
        console.error('There was an error fetching the user libraries!', error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div className="text-center mt-5"><ButtonLoader loading={loading} text='Loading...'/></div>;
  }

  return (
    <div className="container mt-4">
        <h2>Library Users</h2>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>FullName</th>
                    <th>Username</th>
                    <th>Email</th>
                    <th>Mobile Number</th>
                    <th>Address</th>
                  </tr>
                </thead>
                <tbody>
                  {userLibraries.map((userLibrary, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{userLibrary.first_name || 'N/A'} {userLibrary.last_name || ''}</td>
                      <td>{userLibrary.username}</td>
                      <td>{userLibrary.email}</td>
                      <td>{userLibrary.userprofile?.mobile_number || 'N/A'}</td>
                      <td>{userLibrary.userprofile?.address || 'N/A'}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
    </div>
  );
};

export default UserLibrary;
