import React from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL, CDN_URL } from './Context';

//////////////////////////////////////////////////////////////
//////////  class based react component( navbar)    //////////
//////////////////////////////////////////////////////////////

class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loggedIn: !!localStorage.getItem('accessToken'), // Changed to boolean
            libraryStatus: 'unknown',
        };
        this._isMounted = false; // Initialize _isMounted
    }

    componentDidMount() {
        this._isMounted = true; // Track whether component is mounted

        axios.get(`${BASE_URL}/api/v1/libraries/`)
            .then(response => {
                if (this._isMounted) {
                    const libraryStatus = response.data.results.length > 0
                        ? response.data.results[0].library_status
                        : 'unknown';
                    this.setState({ libraryStatus });
                }
            })
            .catch(error => {
                if (this._isMounted) {
                    console.error('Error fetching library status:', error);
                    this.setState({ libraryStatus: 'Sign in to know' });
                }
            });
    }

    componentWillUnmount() {
        this._isMounted = false; // Clean up flag
    }

    render() {
        const { libraryStatus, loggedIn } = this.state;

        return (
            <div>
                <nav className="navbar navbar-expand-lg navbar-dark bg-dark p-2">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="/">
                            <img src={`${CDN_URL}/Images/logo-wbg.png`} alt="Library Logo" width="100%" height="47" />&nbsp;
                        </a>
                        <img src={`${CDN_URL}/Images/shlok.png`} alt="Shlok" height="47" />&nbsp;

                        {loggedIn ? (
                            <div className='status-img-container'>
                                {libraryStatus === 'open' ? (
                                    <>
                                        <img className='status-img' src={`${CDN_URL}/Images/open_door.webp`} alt="Open Door" height="40" width="55" />
                                        <small className='text-white status-text'>{libraryStatus}</small>
                                    </>
                                ) : (
                                    <>
                                        <img className='status-img' src={`${CDN_URL}/Images/closed.webp`} alt="Closed Door" height="40" width="55" />
                                        <small className='text-white status-text'>{libraryStatus}</small>
                                    </>
                                )}
                            </div>
                        ) : (
                            <small className='text-white status-text' style={{ fontSize: '8px' }}>
                                Sign in to know the open/closed
                            </small>
                        )}

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            </ul>

                            <React.Fragment>
                                <NavLink className="nav-item nav-link" to="/">Home</NavLink>
                                <NavLink className="nav-item nav-link" to="/about">About Us</NavLink>
                                {loggedIn ? (
                                    <React.Fragment>
                                        <NavLink className="nav-item nav-link" to='/dashboard'>Dashboard</NavLink>
                                        <NavLink className="nav-item nav-link" to='/attendance'>Attendance</NavLink>
                                        <NavLink className="nav-item nav-link" to="/signout">Sign Out</NavLink>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <NavLink className="nav-item nav-link" to="/signup">Sign Up</NavLink>
                                        <NavLink className="nav-item nav-link" to="/signin">Sign In</NavLink>
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        </div>
                    </div>
                </nav>
            </div>
        );
    }
}

export default Navbar;

/////////////////////////////////////////////////////////////
////////////      end class based component     /////////////
/////////////////////////////////////////////////////////////
