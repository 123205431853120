import React from 'react'
export const myContext = React.createContext();
export const Provider = myContext.Provider;
export const Consumer = myContext.Consumer;
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const CLIENT_ID = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID;
export const CDN_URL = process.env.REACT_APP_CDN_URL;
export const LIBRARY_ADMIN_1_NAME = process.env.REACT_APP_LIBRARY_ADMIN_1_NAME;
export const LIBRARY_ADMIN_2_NAME = process.env.REACT_APP_LIBRARY_ADMIN_2_NAME;
export const LIBRARY_NAME = process.env.REACT_APP_LIBRARY_NAME;
export const LIBRARY_ADDRESS = process.env.REACT_APP_LIBRARY_ADDRESS;
export const LIBRARY_LOCATION = process.env.REACT_APP_LIBRARY_LOCATION;
export const LIBRARY_CONTACT1 = process.env.REACT_APP_LIBRARY_CONTACT1;
export const LIBRARY_CONTACT2 = process.env.REACT_APP_LIBRARY_CONTACT2;