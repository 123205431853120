import React from 'react';
import './gallery.css'
import { CDN_URL } from './Context';

export default function Gallery() {
    return (
        <div id='gallery' className="gallery mt-5">
            <div className='section-header'>
                <h2 className=''>Gallery</h2>
                <p>Glimpses of the memorable moments</p>
                <p><i>Welcome to your memories full of Zeal and Zest</i></p>
            </div>
            <div className="container page-top">
                <div className="row">
                <div className="col-lg-3 col-md-4 col-xs-6 thumb">
                        <div id="transZ"  className="fancybox" rel="ligthbox">
                            <img src={(`${CDN_URL}/Images/library (13).jpg`)} className="zoom img-fluid " alt=""/>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4 col-xs-6 thumb">
                        <div id="transZ"  className="fancybox" rel="ligthbox">
                            <img src={(`${CDN_URL}/Images/library (17).jpg`)} className="zoom img-fluid " alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-xs-6 thumb">
                        <div id="transZ"  className="fancybox" rel="ligthbox">
                            <img src={(`${CDN_URL}/Images/library (11).jpg`)} className="zoom img-fluid " alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-xs-6 thumb">
                        <div id="transZ" className="fancybox" rel="ligthbox">
                        <img src={(`${CDN_URL}/Images/library (10).jpg`)} className="zoom img-fluid " alt=""/>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4 col-xs-6 thumb">
                        <div id="transZ" className="fancybox" rel="ligthbox">
                            <img src={(`${CDN_URL}/Images/library (2).jpg`)} className="zoom img-fluid " alt=""/>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4 col-xs-6 thumb">
                        <div id="transZ"  className="fancybox" rel="ligthbox">
                            <img src={(`${CDN_URL}/Images/library (3).jpg`)} className="zoom img-fluid " alt=""/>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4 col-xs-6 thumb">
                        <div id="transZ"  className="fancybox" rel="ligthbox">
                            <img src={(`${CDN_URL}/Images/library (4).jpg`)} className="zoom img-fluid " alt=""/>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4 col-xs-6 thumb">
                        <div id="transZ"  className="fancybox" rel="ligthbox">
                            <img src={(`${CDN_URL}/Images/library (5).jpg`)} className="zoom img-fluid " alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-xs-6 thumb">
                        <div id="transZ"  className="fancybox" rel="ligthbox">
                            <img src={(`${CDN_URL}/Images/library (6).jpg`)} className="zoom img-fluid " alt=""/>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4 col-xs-6 thumb">
                        <div id="transZ"  className="fancybox" rel="ligthbox">
                            <img src={(`${CDN_URL}/Images/library (7).jpg`)} className="zoom img-fluid " alt=""/>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4 col-xs-6 thumb">
                        <div id="transZ"  className="fancybox" rel="ligthbox">
                            <img src={(`${CDN_URL}/Images/library (8).jpg`)} className="zoom img-fluid " alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-xs-6 thumb">
                        <div id="transZ"  className="fancybox" rel="ligthbox">
                            <img src={(`${CDN_URL}/Images/library (9).jpg`)} className="zoom img-fluid " alt=""/>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}