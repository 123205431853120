// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { BASE_URL } from './Context';
// import { Tab, Tabs, Container } from 'react-bootstrap';
// import LibraryChairs from './LibraryChairs';

// const ShiftScheduler = () => {
//   const loggedIn = localStorage.getItem('accessToken');
//   const [shifts, setShifts] = useState([]);
//   const [shiftData, setShiftData] = useState({});
//   const [selectedShift, setSelectedShift] = useState(null);
//   const [totalSeats, setTotalSeats] = useState(0);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchLibraryData = async () => {
//       try {
//         const libraryResponse = await axios.get(`${BASE_URL}/api/v1/library/`);

//         if (libraryResponse.data) {
//           const library = libraryResponse.data.results[0];
//           const shifts = library.shifts;
//           const totalSeats = library.total_seats;

//           const shiftData = shifts.reduce((acc, shift) => {
//             const bookedSeats = shift.seats.map(seat => seat.seat_number);

//             // Handle overlapping shifts (Morning, Evening, and Full-Day)
//             if (shift.shift_type === 'M' || shift.shift_type === 'E') {
//               const overlappingFullDayShifts = shifts.filter(s =>
//                 s.shift_type === 'F' &&
//                 ((s.start_time <= shift.start_time && s.end_time >= shift.end_time) ||
//                  (s.start_time >= shift.start_time && s.end_time <= shift.end_time))
//               );

//               overlappingFullDayShifts.forEach(fullDayShift => {
//                 const fullDayBookedSeats = fullDayShift.seats.map(seat => seat.seat_number);
//                 bookedSeats.push(...fullDayBookedSeats);
//               });
//             }

//             if (shift.shift_type === 'F') {
//               const overlappingShifts = shifts.filter(s =>
//                 (s.shift_type === 'M' || s.shift_type === 'E') &&
//                 ((s.start_time >= shift.start_time && s.end_time <= shift.end_time))
//               );

//               overlappingShifts.forEach(overlapShift => {
//                 const overlapBookedSeats = overlapShift.seats.map(seat => seat.seat_number);
//                 bookedSeats.push(...overlapBookedSeats);
//               });
//             }

//             // Remove duplicates from bookedSeats
//             acc[shift.id] = { ...shift, bookedSeats: [...new Set(bookedSeats)] };
//             return acc;
//           }, {});

//           // Debug: Log organized shift data
//           console.log("Organized Shift Data:", shiftData);

//           setShifts(shifts);
//           setShiftData(shiftData);
//           setTotalSeats(totalSeats);

//           // Optionally set the default selected shift if no shift is selected
//           if (shifts.length > 0 && selectedShift === null) {
//             setSelectedShift(shifts[0].id);
//           }
//         } else {
//           setError('Unexpected response format');
//         }
//       } catch (error) {
//         console.error('Error fetching data:', error);
//         setError('Error fetching data');
//       }
//     };

//     if (loggedIn) {
//       fetchLibraryData();
//     }
//   }, [loggedIn, selectedShift]);

//   const handleTabClick = (shiftId) => {
//     setSelectedShift(shiftId);
//     console.log('Shift changed, now shiftId:', shiftId);
//   };

//   return (
//     <Container className="my-5">
//       <div className='section-header my-5'>
//         <h2 className='text-center'>Seating Arrangement</h2>
//         <p className='text-center'>Choose your desired seat in your desired Shift</p>
//       </div>
//       {error && <p className="text-danger text-center">{error}</p>}
//       <Tabs
//         activeKey={selectedShift}
//         id="shift-tabs"
//         className="mb-3 bg-dark"
//         onSelect={handleTabClick}
//       >
//         {shifts.map((shift) =>{
//           const startTime = shift.start_time.slice(0, 5);
//           const endTime = shift.end_time.slice(0, 5);
//           const shift_type = shift.shift_type === 'M' ? 'Morning' : (shift.shift_type === "E" ? "Evening" : (shift.shift_type === "N" ? "Night" : "Full-day"));

//           return (
//             <Tab eventKey={shift.id} title={`${startTime} - ${endTime} ${shift_type} (${shift.price} ₹)`} key={shift.id}>
//               <div className="p-3">
//                 <LibraryChairs key={selectedShift} shiftData={shiftData[shift.id]} totalSeats={totalSeats} activeShift={selectedShift} />
//               </div>
//             </Tab>
//           )
//         })}
//       </Tabs>
//     </Container>
//   );
// };

// export default ShiftScheduler;








import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from './Context';
import { Tab, Tabs, Container } from 'react-bootstrap';
import LibraryChairs from './LibraryChairs';

const ShiftScheduler = () => {
  const loggedIn = localStorage.getItem('accessToken');
  const [shifts, setShifts] = useState([]);
  const [shiftData, setShiftData] = useState({});
  const [selectedShift, setSelectedShift] = useState(null);
  const [totalSeats, setTotalSeats] = useState(0);
  const [error, setError] = useState(null);

  useEffect(() => {
    let isMounted = true; // Track mounted status

    const fetchLibraryData = async () => {
      try {
        const libraryResponse = await axios.get(`${BASE_URL}/api/v1/library/`);

        if (isMounted && libraryResponse.data) {
          const library = libraryResponse.data.results[0];
          const shifts = library.shifts;
          const totalSeats = library.total_seats;

          const shiftData = shifts.reduce((acc, shift) => {
            const bookedSeats = shift.seats.map(seat => seat.seat_number);

            // Handle overlapping shifts (Morning, Evening, and Full-Day)
            if (shift.shift_type === 'M' || shift.shift_type === 'E') {
              const overlappingFullDayShifts = shifts.filter(s =>
                s.shift_type === 'F' &&
                ((s.start_time <= shift.start_time && s.end_time >= shift.end_time) ||
                 (s.start_time >= shift.start_time && s.end_time <= shift.end_time))
              );

              overlappingFullDayShifts.forEach(fullDayShift => {
                const fullDayBookedSeats = fullDayShift.seats.map(seat => seat.seat_number);
                bookedSeats.push(...fullDayBookedSeats);
              });
            }

            if (shift.shift_type === 'F') {
              const overlappingShifts = shifts.filter(s =>
                (s.shift_type === 'M' || s.shift_type === 'E') &&
                ((s.start_time >= shift.start_time && s.end_time <= shift.end_time))
              );

              overlappingShifts.forEach(overlapShift => {
                const overlapBookedSeats = overlapShift.seats.map(seat => seat.seat_number);
                bookedSeats.push(...overlapBookedSeats);
              });
            }

            // Remove duplicates from bookedSeats
            acc[shift.id] = { ...shift, bookedSeats: [...new Set(bookedSeats)] };
            return acc;
          }, {});

          // Debug: Log organized shift data
          console.log("Organized Shift Data:", shiftData);

          if (isMounted) {
            setShifts(shifts);
            setShiftData(shiftData);
            setTotalSeats(totalSeats);

            // Optionally set the default selected shift if no shift is selected
            if (shifts.length > 0 && selectedShift === null) {
              setSelectedShift(shifts[0].id);
            }
          }
        } else if (isMounted) {
          setError('Unexpected response format');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        if (isMounted) {
          setError('Error fetching data');
        }
      }
    };

    if (loggedIn) {
      fetchLibraryData();
    }

    return () => {
      isMounted = false; // Cleanup on unmount
    };
  }, [loggedIn, selectedShift]);

  const handleTabClick = (shiftId) => {
    setSelectedShift(shiftId);
    console.log('Shift changed, now shiftId:', shiftId);
  };

  return (
    <Container className="my-5">
      <div className='section-header my-5'>
        <h2 className='text-center'>Seating Arrangement</h2>
        <p className='text-center'>Choose your desired seat in your desired Shift</p>
      </div>
      {error && <p className="text-danger text-center">{error}</p>}
      <Tabs
        activeKey={selectedShift}
        id="shift-tabs"
        className="mb-3 bg-dark"
        onSelect={handleTabClick}
      >
        {shifts.map((shift) => {
          const startTime = shift.start_time.slice(0, 5);
          const endTime = shift.end_time.slice(0, 5);
          const shift_type = shift.shift_type === 'M' ? 'Morning' : (shift.shift_type === "E" ? "Evening" : (shift.shift_type === "N" ? "Night" : "Full-day"));

          return (
            <Tab eventKey={shift.id} title={`${startTime} - ${endTime} ${shift_type} (${shift.price} ₹)`} key={shift.id}>
              <div className="p-3">
                <LibraryChairs key={selectedShift} shiftData={shiftData[shift.id]} totalSeats={totalSeats} activeShift={selectedShift} />
              </div>
            </Tab>
          );
        })}
      </Tabs>
    </Container>
  );
};

export default ShiftScheduler;


