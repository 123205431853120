import React from 'react';

const ButtonLoader = ({ loading, text }) => {
  return (
    <span style={{ display: 'inline-flex', alignItems: 'center' }}>
      {loading && (
        <div
          style={{
            border: '3px solid #f3f3f3', // Light grey border
            borderTop: '3px solid #000', // Black color for the top border
            borderRadius: '50%',
            width: '23px', // Size of the loader
            height: '23px', // Size of the loader
            backgroundColor: 'transparent', // Transparent background
            animation: 'spin 1.5s linear infinite',
            marginRight: '8px', // Space between loader and text
          }}
        />
      )}
      {text}
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
    </span>
  );
};

export default ButtonLoader;
