import React from 'react';
import { Navigate } from 'react-router-dom';

const SignOut = props => {
  if (localStorage.getItem('accessToken')) {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('username');
    localStorage.removeItem('userID');
  }
  return <Navigate to="/" />;
}

export default SignOut;