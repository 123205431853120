// src/components/OfferList.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL } from './Context';
import { useNavigate } from 'react-router-dom';
import { Alert, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const OfferList = () => {
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOffers = async () => {
      const token = localStorage.getItem('accessToken'); // Replace with your auth check

      if (!token) {
        setError('Please sign in to view offers.');
        setLoading(false);
        return;
      }

      try {
        console.log('Fetching offers from API...');
        const response = await axios.get(`${BASE_URL}/api/v1/offers/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log('API response:', response);
        const offersData = response.data.results;

        const now = new Date();
        const activeAndValidOffers = offersData.filter((offer) => {
          const startDate = new Date(offer.start_date);
          const endDate = new Date(offer.end_date);
          return offer.active && startDate <= now && endDate >= now;
        });

        console.log('Filtered active and valid offers:', activeAndValidOffers);
        setOffers(activeAndValidOffers);
      } catch (error) {
        console.error('Error fetching offers:', error);
        setError('Error loading offers!');
      } finally {
        setLoading(false);
      }
    };

    fetchOffers();
  }, []);

  if (loading) return <p>Loading...</p>;

  return (
    <div className="container mt-4">
      <div className="row">
        {error ? (
          <div className="col-12">
            <Alert variant="warning" className="d-flex justify-content-between align-items-center">
              <span>{error}</span>
              <Button variant="primary" onClick={() => navigate('/signin/')}>
                Login
              </Button>
            </Alert>
          </div>
        ) : offers.length === 0 ? (
          <p>No active offers available at the moment.</p>
        ) : (
          offers.map((offer) => (
            <div className="col-md-6 mb-2" key={offer.id}>
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title text-center mb-3">📚🎉🎆🎊 <b style={{"textTransform": "uppercase" }}><u>{offer.title}</u></b>🎊🎇🎈🍾</h5>
                  <p className="card-text">{offer.description}</p>
                  <p className="card-text">
                    <strong>Discount:</strong> {offer.discount_percentage}%
                  </p>
                  <small className="card-text">
                    <strong>Validity:</strong> Offer Valid till {new Date(offer.end_date).toLocaleDateString()} only. Join Soon!!!
                  </small>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default OfferList;
