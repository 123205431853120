import React from 'react';
import "./ven.css";
import { CDN_URL , LIBRARY_NAME , LIBRARY_ADDRESS , LIBRARY_LOCATION} from './Context';


function Venue() {
  return (
    <div>
      <div className='section-header my-5'>
        <h2 className='text-center'>Library Location</h2>
        <p className='text-center'>Checkout library location on Google map</p>
      </div>
      <div className='container mt-5'>
        <div className='row'>
          <div id="map-container-google-3" className="col-lg-6 z-depth-1-half map-container-3">
          <iframe  title='map' src={LIBRARY_LOCATION}
              style={{ border: "0" }}>
          </iframe>
          </div>
          <div className="col-lg-6 venue-info">
            <div className="row">
              <img className='img-fluid' src={(`${CDN_URL}/Images/library_venue (1).jpg`)} alt="library img" />
            </div>
          </div>
        </div>
      </div>
      <div className="container mb-5">
        <div className='section-header '>
          <h2 className="text-center">Video Gallery</h2>
          <p><i>Your own virtual tour of our venues</i></p>
        </div>
        <div className="video-gallery">
          <div className='row'>
            <div className="gallery-item">
              <a className="vimeo-popup" href="https://www.youtube.com/watch?v=Sg9h2H0Q0o0 rel='noreferrer'">
                <img src={(`${CDN_URL}/Images/library_venue (2).jpg`)} alt="15 August Celebration" />
                <div className="gallery-item-caption">
                  <div>
                    <h2>15 August 2024</h2>
                    <p>Memorable Moments</p>
                  </div>
                </div>
              </a>
            </div>

            <div className="gallery-item ">
              <a className="vimeo-popup" href="https://www.youtube.com/watch?v=Sg9h2H0Q0o0" rel='noreferrer'>
                <img src={(`${CDN_URL}/Images/library_venue (3).jpg`)} alt="MMMUT " />
                <div className="gallery-item-caption">
                  <div>
                    <h2>{LIBRARY_NAME}</h2>
                    <p>{LIBRARY_ADDRESS}</p>

                  </div>
                </div>
              </a>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Venue
