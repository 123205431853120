import React from 'react'
import {  NavLink } from 'react-router-dom';
import { CDN_URL , LIBRARY_NAME , LIBRARY_ADDRESS, LIBRARY_CONTACT1,LIBRARY_CONTACT2 } from './Context';

export default function Footer() {
    return (
        <>
<div>

    <footer id="footer" >
        <div className="footer-top">
        <div className="container">
            <div className="row">

            <div className="col-md-4 p-3 footer-info">
                <h4><img src={(`${CDN_URL}/Images/logo-wbg.png`)} alt="icon1" width='47%'/>&nbsp; </h4>
                <p>Website For {LIBRARY_NAME}</p>
                <p><i className="bi bi-geo-alt"></i> &nbsp;{LIBRARY_ADDRESS}</p>
                <strong>Phone:</strong> {LIBRARY_CONTACT1} <br/>
                <strong>Phone:</strong> {LIBRARY_CONTACT2} <br/> <br/>
                <div className="social-links">
                <a href="https://alokknight.com" className="twitter"> <i className="fa fa-twitter rotate360"></i></a>
                <a href="https://alokknight.com" className="facebook"><i className="fa fa-facebook rotate360"></i></a>
                <a href="https://alokknight.com" className="instagram"><i className="fa fa-instagram rotate360"></i></a>
                <a href="https://alokknight.com" className="google-plus"><i className="fa fa-google-plus rotate360"></i></a>
                </div>
            </div>

            <div className="col-md-4 p-3 footer-links">
                <h4>Useful Links</h4>
                <ul>
                <li><i className="fa fa-angle-right"></i> <a href="/">Home</a></li>
                <li><i className="fa fa-angle-right"></i> <a href="/about">About us</a></li>
                <li><i className="fa fa-angle-right"></i> <a href="/contactus">Contact Us</a></li>
                </ul>
            </div>

            <div className="col-md-4 p-3 footer-contact">
                <h4>DEVELOPED BY</h4>
                <p>
                <strong>ALOK PATEL </strong><br/>
                <NavLink className="nav-item nav-link" to="/contactus">Contact Developer</NavLink>
                Sitapur, UP, India<br/>
                <strong>Phone:</strong> +91 63 8686 6361<br/>
                <strong>Email:</strong> <a href="mailto:alokpatel885@gmail.com">alokpatel885@gmail.com</a><br/>
                </p>

                <div className="social-links">
                <a href="https://alokknight.com" className="twitter"> <i className="fa fa-twitter rotate360"></i></a>
                <a href="https://alokknight.com" className="facebook"><i className="fa fa-facebook rotate360"></i></a>
                <a href="https://alokknight.com" className="instagram"><i className="fa fa-instagram rotate360"></i></a>
                <a href="https://alokknight.com" className="google-plus"><i className="fa fa-google-plus rotate360"></i></a>
                <a href="https://alokknight.com" className="linkedin"><i className="fa fa-linkedin rotate360"></i></a>
                </div>

            </div>

            </div>
        </div>
        </div>

        <div className="container mb-5 mb-md-0">
            <div className="copyright">
                &copy; Copyright <strong></strong>. All Rights Reserved
            </div>
            <div className="credits">
                Made with 💖
            </div>
        </div>
        {/* <a href="#" className="back-to-top"><i className="fa fa-angle-up mx-4"></i></a> */}
</footer>

</div>
</>
    )
}