import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL } from './Context';
import { Link } from 'react-router-dom';

const filterData = (data, filter) => {
    return data.filter(item => {
        return (
            (!filter.seatNumber || item.seat.seat_number.toString().includes(filter.seatNumber)) &&
            (!filter.shift || item.shift.id.toString() === filter.shift) &&
            (!filter.user || item.username.includes(filter.user)) &&
            (!filter.status || item.status === filter.status) &&
            (!filter.shiftType || item.shift.shift_type === filter.shiftType)
        );
    });
};

const sortData = (data, sort) => {
    return data.sort((a, b) => {
        let comparison = 0;
        if (a[sort.field] > b[sort.field]) {
            comparison = 1;
        } else if (a[sort.field] < b[sort.field]) {
            comparison = -1;
        }
        return sort.order === 'asc' ? comparison : -comparison;
    });
};

const SeatBookings = ({ userrole }) => {
    // const [seatBookings, setSeatBookings] = useState([]);
    const [filteredAndSortedBookings, setFilteredAndSortedBookings] = useState([]);
    const [editingId, setEditingId] = useState(null);
    const [newStatus, setNewStatus] = useState('');

    // State for filtering
    const [filter, setFilter] = useState({
        seatNumber: '',
        shift: '',
        user: '',
        status: '',
        shiftType: ''
    });

    // State for sorting
    const [sort, setSort] = useState({
        field: 'booking_time',
        order: 'dsc',
    });

    // Status options
    const statusOptions = ['pending', 'confirmed', 'failed', 'expired', 'denied'];

    // Shift type options (M: morning, E: evening, N: night, F: full-day)
    const shiftTypeOptions = {
        M: 'Morning',
        E: 'Evening',
        N: 'Night',
        F: 'Full-Day'
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/api/v1/seatbookings/`);
                const data = response.data.results || [];
                const filteredData = filterData(data, filter);
                const sortedData = sortData(filteredData, sort);
                setFilteredAndSortedBookings(sortedData);
            } catch (error) {
                console.error('Error fetching seat bookings:', error);
            }
        };

        fetchData();
    }, [filter, sort]);

    const handleStatusUpdate = (id) => {
        axios.put(`${BASE_URL}/api/v1/booking/${id}/`, { status: newStatus }, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                if (response.status === 200) {
                    setFilteredAndSortedBookings(filteredAndSortedBookings.map(seatBooking =>
                        seatBooking.id === id
                            ? { ...seatBooking, status: newStatus }
                            : seatBooking
                    ));
                    setEditingId(null);
                    setNewStatus('');
                } else {
                    alert('Failed to update the booking status.');
                }
            })
            .catch(error => console.error('Error updating booking status:', error));
    };

    const handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this booking?')) {
            axios.delete(`${BASE_URL}/api/v1/booking/${id}/`, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
                .then(response => {
                    if (response.status === 204) {
                        setFilteredAndSortedBookings(filteredAndSortedBookings.filter(seatBooking => seatBooking.id !== id));
                    } else {
                        alert('Failed to delete the booking.');
                    }
                })
                .catch(error => console.error('Error deleting booking:', error));
        }
    };

    return (
        <div>
            {/* Filters */}
            <div className="filters mb-3">
                <input
                    type="text"
                    placeholder="Filter by seat number"
                    value={filter.seatNumber}
                    onChange={(e) => setFilter({ ...filter, seatNumber: e.target.value })}
                />
                <input
                    type="text"
                    placeholder="Filter by user"
                    value={filter.user}
                    onChange={(e) => setFilter({ ...filter, user: e.target.value })}
                />
                <select
                    onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                    value={filter.status}
                >
                    <option value="">All Statuses</option>
                    {statusOptions.map(statusOption => (
                        <option key={statusOption} value={statusOption}>
                            {statusOption}
                        </option>
                    ))}
                </select>
                <select
                    onChange={(e) => setFilter({ ...filter, shiftType: e.target.value })}
                    value={filter.shiftType}
                >
                    <option value="">All Shifts</option>
                    {Object.entries(shiftTypeOptions).map(([code, label]) => (
                        <option key={code} value={code}>
                            {label}
                        </option>
                    ))}
                </select>
            </div>

            {/* Sorting */}
            <div className="sorting mb-3">
                <select onChange={(e) => setSort({ ...sort, field: e.target.value })}>
                    <option value="booking_time">Booking Time</option>
                    <option value="end_date">End Date</option>
                </select>
                <select onChange={(e) => setSort({ ...sort, order: e.target.value })}>
                    <option value="desc">Descending</option>
                    <option value="asc">Ascending</option>
                </select>
            </div>

            {/* Seat Bookings */}
            {filteredAndSortedBookings.length > 0 ? (
                filteredAndSortedBookings.map(seatBooking => (
                    <div key={seatBooking.id} className="p-3 mb-2 text-black border">
                        <table>
                        <tbody>
                            <tr>
                                <th className="text-start">Seat No:</th>
                                <td className="text-danger">{seatBooking.seat.seat_number}</td>
                            </tr>
                            <tr>
                                <th className="text-start">Shift:</th>
                                <td className="text-danger">{seatBooking.shift.start_time.slice(0, 5)} - {seatBooking.shift.end_time.slice(0, 5)} ({shiftTypeOptions[seatBooking.shift.shift_type]})</td>
                            </tr>
                            <tr>
                                <th className="text-start">Status:</th>
                                <td className="text-danger">
                                    {editingId === seatBooking.id ? (
                                        <select
                                            value={newStatus}
                                            onChange={(e) => setNewStatus(e.target.value)}
                                        >
                                            {statusOptions.map(statusOption => (
                                                <option key={statusOption} value={statusOption}>
                                                    {statusOption}
                                                </option>
                                            ))}
                                        </select>
                                    ) : (
                                        seatBooking.status
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th className="text-start">Duration:</th>
                                <td className="text-danger">{seatBooking.no_of_months} month(s)</td>
                            </tr>
                            <tr>
                                <th className="text-start">Booked By:</th>
                                <td className="text-danger">{seatBooking.username}</td>
                            </tr>
                            <tr>
                                <th className="text-start">Booking Time:</th>
                                <td className="text-danger">{new Date(seatBooking.booking_time).toLocaleString()}</td>
                            </tr>
                            <tr>
                                <th className="text-start">End Date:</th>
                                <td className="text-danger">{seatBooking.end_date ? new Date(seatBooking.end_date).toLocaleString().slice(0, 17) : 'N/A'}</td>
                            </tr>
                        </tbody>
                        </table>

                            {/* Action Buttons */}
                            {userrole && userrole.role === "Library_Admin" && (
                                <div className="d-flex flex-mb-row mt-2">
                                    {editingId === seatBooking.id ? (
                                        <>
                                            <button
                                                className="btn btn-sm btn-success me-2"
                                                onClick={() => handleStatusUpdate(seatBooking.id)}
                                            >
                                                Save
                                            </button>
                                            <button
                                                className="btn btn-sm btn-secondary me-2"
                                                onClick={() => {
                                                    setEditingId(null);
                                                    setNewStatus('');
                                                }}
                                            >
                                                Cancel
                                            </button>
                                        </>
                                    ) : (
                                        <button
                                            className="btn btn-sm btn-primary me-2"
                                            onClick={() => {
                                                setEditingId(seatBooking.id);
                                                setNewStatus(seatBooking.status);
                                            }}
                                        >
                                            Edit Status
                                        </button>
                                    )}
                                    <button
                                        className="btn btn-sm btn-danger"
                                        onClick={() => handleDelete(seatBooking.id)}
                                    >
                                        Delete
                                    </button>


                                </div>
                            )}

                            {seatBooking.status && seatBooking.status==='pending' &&(
                                <Link to={`/payment/${seatBooking.id}`} className='btn btn-sm btn-primary m-1' >make payment</Link>
                            )}

                    </div>
                ))
            ) : (
                <p>No bookings available</p>
            )}
        </div>
    );
};

export default SeatBookings;
