// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { BASE_URL } from './Context';
// import ButtonLoader from './ButtonLoader';

// const GoogleSignInButton = () => {
//   const [loading, setLoading] = useState(false);

//   useEffect(() => {
//     const initializeGoogleSignIn = () => {
//       window.google.accounts.id.initialize({
//         client_id: '663983064088-sae31117fr0kpslfs4q864ci3kc9vsnv.apps.googleusercontent.com',
//         callback: handleCredentialResponse,
//       });

//       window.google.accounts.id.renderButton(
//         document.getElementById('signInDiv'),
//         { theme: 'outline', size: 'large' }
//       );

//       window.google.accounts.id.prompt();
//     };

//     const handleCredentialResponse = (response) => {
//       setLoading(true);

//       axios
//         .post(
//           `${BASE_URL}/social_auth/google/`,
//           { auth_token: response.credential },
//           {
//             headers: {
//               'Content-Type': 'application/json',
//             },
//           }
//         )
//         .then((res) => {
//           setLoading(false);
//           const { tokens, username } = res.data;

//           localStorage.setItem('accessToken', tokens.access);
//           localStorage.setItem('refreshToken', tokens.refresh);
//           localStorage.setItem('username', username);

//           window.location.href = '/';
//         })
//         .catch((error) => {
//           setLoading(false);
//           console.error('Error during Google login:', error);
//         });
//     };

//     initializeGoogleSignIn();
//   }, []);

//   return (
//     <div id="signInDiv" className="text-center mt-3">
//       <button disabled={loading} style={{ display: 'flex', alignItems: 'center', backgroundColor: '#fff', padding: '10px', borderRadius: '2px', cursor: loading ? 'not-allowed' : 'pointer' }}>
//         <ButtonLoader loading={loading} text="Login with Google" />
//         {!loading && (
//           <div style={{ marginLeft: '10px' }}>
//             <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
//               <g fill="#000" fillRule="evenodd">
//                 <path
//                   d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z"
//                   fill="#EA4335"
//                 />
//                 <path
//                   d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z"
//                   fill="#4285F4"
//                 />
//                 <path
//                   d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z"
//                   fill="#FBBC05"
//                 />
//                 <path
//                   d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z"
//                   fill="#34A853"
//                 />
//                 <path fill="none" d="M0 0h18v18H0z" />
//               </g>
//             </svg>
//           </div>
//         )}
//       </button>
//     </div>
//   );
// };

// export default GoogleSignInButton;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL } from './Context';
import ButtonLoader from './ButtonLoader';
import {  useNavigate } from 'react-router-dom';

const GoogleSignInButton = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const initializeGoogleSignIn = () => {
      window.google.accounts.id.initialize({
        client_id: '663983064088-sae31117fr0kpslfs4q864ci3kc9vsnv.apps.googleusercontent.com',
        callback: handleCredentialResponse,
      });

      window.google.accounts.id.renderButton(
        document.getElementById('signInDiv'),
        { theme: 'outline', size: 'large' }
      );

      window.google.accounts.id.prompt();
    };

    const handleCredentialResponse = (response) => {
      setLoading(true);

      axios
        .post(
          `${BASE_URL}/social_auth/google/`,
          { auth_token: response.credential },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then((res) => {
          setLoading(false);
          const { tokens, username } = res.data;

          localStorage.setItem('accessToken', tokens.access);
          localStorage.setItem('refreshToken', tokens.refresh);
          localStorage.setItem('username', username);

          window.location.href = '/';
        })
        .catch((error) => {
          setLoading(false);
          console.error('Error during Google login:', error);
        });
    };

    if (window.google && window.google.accounts) {
      initializeGoogleSignIn();
    }
    else {
      navigate('/signin')
    //   const script = document.createElement('script');
    //   script.src = 'https://accounts.google.com/gsi/client';
    //   script.async = true;
    //   script.defer = true;
    //   script.onload = initializeGoogleSignIn;
    //   document.body.appendChild(script);
    }
  }, []);

  return (
    <div id="signInDiv" className="text-center mt-3">
      <button disabled={loading} style={{ display: 'flex', alignItems: 'center', backgroundColor: '#fff', padding: '10px', borderRadius: '2px', cursor: loading ? 'not-allowed' : 'pointer' }}>
        <ButtonLoader loading={loading} text="Login with Google" />
        {!loading && (
          <div style={{ marginLeft: '10px' }}>
            <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
              <g fill="#000" fillRule="evenodd">
                <path
                  d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z"
                  fill="#EA4335"
                />
                <path
                  d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z"
                  fill="#4285F4"
                />
                <path
                  d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z"
                  fill="#FBBC05"
                />
                <path
                  d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z"
                  fill="#34A853"
                />
                <path fill="none" d="M0 0h18v18H0z" />
              </g>
            </svg>
          </div>
        )}
      </button>
    </div>
  );
};

export default GoogleSignInButton;
