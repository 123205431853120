// import axios from 'axios';

// // Axios Interceptor Configuration
// axios.interceptors.request.use(config => {
//     const accessToken = localStorage.getItem('accessToken');
//     if (accessToken) {
//         config.headers['Authorization'] = `Bearer ${accessToken}`;
//     }
//     return config;
// }, error => {
//     return Promise.reject(error);
// });




import axios from 'axios';
import { BASE_URL } from './components/Context';

// Axios Request Interceptor
axios.interceptors.request.use(config => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

// Axios Response Interceptor to handle 401 errors
axios.interceptors.response.use(response => {
    return response;
}, async error => {
    const originalRequest = error.config;

    // Check if the error is due to unauthorized access (401) and it's not a retry
    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        // Get the refresh token
        const refreshToken = localStorage.getItem('refreshToken');

        if (refreshToken) {
            try {
                // Request a new access token using the refresh token
                const response = await axios.post(`${BASE_URL}/accounts/token/refresh/`, { refresh: refreshToken });

                // Save the new access token
                const newAccessToken = response.data.access;
                localStorage.setItem('accessToken', newAccessToken);

                // Update the authorization header with the new access token
                axios.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
                originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;

                // Retry the original request
                return axios(originalRequest);
            } catch (err) {
                // Handle error if refresh token is invalid or expired
                console.error("Failed to refresh token:", err);
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
                window.location.href = '/signin';
            }
        }
    }

    return Promise.reject(error);
});
