import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from './Context';

const GenerateQR = () => {
    const [qrPdfUrl, setQrPdfUrl] = useState(null);
    const [error, setError] = useState(null);
    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState(new Date().getMonth() + 1); // Months are 0-based in JavaScript
    const [years, setYears] = useState([]);
    const [months, setMonths] = useState([]);

    useEffect(() => {
        // Generate a list of years for the dropdown
        const currentYear = new Date().getFullYear();
        const yearsList = [];
        for (let i = currentYear; i <= currentYear + 1; i++) {
            yearsList.push(i);
        }
        setYears(yearsList);

        // Generate a list of months for the dropdown
        const monthsList = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        setMonths(monthsList);
    }, []);

    const handleGenerateQR = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/v1/gen_monthly_qr/${year}/${month}/`);
            if (response.data.qr_pdf_url) {
                setQrPdfUrl(response.data.qr_pdf_url);
            } else {
                setError('Failed to generate QR code PDF');
            }
        } catch (err) {
            setError(`Error: ${err.message}`);
        }
    };

    return (
        <div className="text-center mt-2 border rounded p-4">
            <h5 className='text-center'>Generate Monthly QR Codes</h5>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <label htmlFor="year" className="form-label">Year: </label>
                        <select id="year" className="form-select" value={year} onChange={(e) => setYear(Number(e.target.value))}>
                            {years.map(y => (
                                <option key={y} value={y}>{y}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-12 mt-2 mt-md-0">
                        <label htmlFor="month" className="form-label">Month: </label>
                        <select id="month" className="form-select" value={month} onChange={(e) => setMonth(Number(e.target.value))}>
                            {months.map((m, index) => (
                                <option key={index + 1} value={index + 1}>{m}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
            <button className="btn btn-info mt-3" onClick={handleGenerateQR}>Generate QRCodes PDF</button>
            {qrPdfUrl && (
                <div className="text-center mt-4">
                    <h5 >Download PDF</h5>
                    <a href={qrPdfUrl} target="_blank" rel="noopener noreferrer">Download QR Codes PDF</a>
                </div>
            )}
            {error && <p>{error}</p>}
        </div>
    );
};

export default GenerateQR;
