import React, { Component } from 'react';
import { CDN_URL, LIBRARY_ADMIN_1_NAME,LIBRARY_ADMIN_2_NAME,LIBRARY_CONTACT1,LIBRARY_CONTACT2 } from './Context';

class Team extends Component {
    render() {
        return (
            <div className="container">
                <div className="py-5">
                <div className="container py-5">
                    <div className="row mb-4">
                        <div className="section-header">
                            <h2 className="display-4 font-weight-light">Our Team</h2>
                            <p><i>We would love to be in touch with you! Please sign up to receive emails from us</i></p>
                    </div>
                    </div>

                    <div className="row text-center">
                    {/* <!-- Team item--> */}
                    <div className="digital-column col-lg-3 col-xl-3 col-sm-6 mb-5">
                        <div className="digital-image bg-white rounded shadow-sm py-4 px-3">
                        <img src={(`${CDN_URL}/Images/library_admin (1).jpg`)} alt="Library_admin" className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm bg-black"/>
                        <h5 className="mb-0">{LIBRARY_ADMIN_1_NAME}</h5>
                        {LIBRARY_CONTACT1}
                        </div>
                    </div>
                    {/* <!-- End--> */}

                    {/* <!-- Team item--> */}
                    {LIBRARY_ADMIN_2_NAME && (
                        <div className="digital-column col-lg-3 col-xl-3 col-sm-6 mb-5">
                            <div className="digital-image bg-white rounded shadow-sm py-4 px-3">
                                <img src={`${CDN_URL}/Images/library_admin (2).jpg`} alt="Library_admin2" className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm bg-black" />
                                <h5 className="mb-0">{LIBRARY_ADMIN_2_NAME}</h5>
                                {LIBRARY_CONTACT2}
                            </div>
                        </div>
                    )}

                    {/* <!-- Team item--> */}
                    <div className="digital-column col-lg-3 col-xl-3 col-sm-6 mb-5">
                        <div className="digital-image bg-white rounded shadow-sm py-4 px-3">
                        <img src={(`${CDN_URL}/Images/Alok.jpg`)} alt="Software_eng" className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm bg-black"/>
                        <h5 className="mb-0">Alok Patel (Soft.Eng.)</h5>
                        +91 6386866361 (emergency)
                        </div>
                    </div>
                    {/* /* <!-- End--> */}

                    </div>
                </div>
                </div>
            </div>
        );
    }
}

export default Team;