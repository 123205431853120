import React, { Component } from 'react';
import './Help.css';
import Navbar from './Navbar'
import Footer from './Footer'
import { CDN_URL } from './Context';

export default class Help extends Component {
    render() {
        return (
            <>
            <Navbar/>
                <div className="topmargin">
                    <section>
                        <section id="team">
                            <div className="container" data-aos="fade-up">
                                <div className="section-header">
                                    <h2>Our Team</h2>
                                    <p><i>Contact Me if you encounter any technical problems.</i></p>
                                    <p><i>I would love to be in touch with you!</i></p>
                                </div>
                            </div>
                        <div className="container my-5 mb-auto">
                            <div className="row mt-40">
                                <div className="col-md-4 col-sm-12">
                                    <div className="box1 sample mb-5"> <img src={(`${CDN_URL}/Images/Alok.jpg`)} alt="" />
                                        <h3 className="title">ALOK PATEL</h3>
                                        <ul className="icon">
                                            <li><a href="mailto:alokpatel885@gmail.com"><i className="fa fa-envelope rotate360"></i></a></li>
                                            <li><a href="https://www.instagram.com/aalokknight"><i className="fa fa-instagram rotate360"></i></a></li>
                                            <li><a href="https://www.twitter.com/aalokknight"><i className="fa fa-twitter rotate360"></i></a></li>
                                            <li><a href="https://www.linkedin.com/in/alokknight"><i className="fa fa-linkedin rotate360"></i></a></li>
                                            <li><a href="https://www.github.com/alokknight"><i className="fa fa-github rotate360"></i></a></li>
                                            <li><a href="https://alokknight.com"><i className="fa fa-globe rotate360"></i></a></li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                </section>
            </div>
            <Footer/>
            </>

        )
    }
}
