import React, { useState } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { BASE_URL } from './Context';
import Footer from './Footer';
import Navbar from './Navbar';

const HolidayForm = () => {
    const [library, setLibrary] = useState('');
    const [name, setName] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [description, setDescription] = useState('');
    const [isRecurring, setIsRecurring] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loading, setLoading] = useState(false); // State to handle loading state

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setSuccess(null);
        setLoading(true); // Start loading

        try {
            const response = await axios.post(`${BASE_URL}/api/v1/holidays/`, {
                library,
                name,
                start_date: startDate.toISOString().split('T')[0], // Format as YYYY-MM-DD
                end_date: endDate.toISOString().split('T')[0],
                description,
                is_recurring: isRecurring
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            console.log('Holiday created:', response.data);
            setSuccess('Holiday created successfully!');
            setLibrary('');
            setName('');
            setStartDate(null);
            setEndDate(null);
            setDescription('');
            setIsRecurring(false);
        } catch (error) {
            console.error('Error creating holiday:', error.response ? error.response.data : error.message);
            setError('Failed to create holiday. Please try again.');
        } finally {
            setLoading(false); // Stop loading
        }
    };

    return (
        <>
        <Navbar/>
        <div className="container col-md-6">
            <h1>Create Holiday</h1>
            {error && <div className="alert alert-danger" role="alert">{error}</div>}
            {success && <div className="alert alert-success" role="alert">{success}</div>}
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="library">Library ID:</label>
                    <input
                        type="text"
                        id="library"
                        className="form-control"
                        value={library}
                        placeholder='1'
                        onChange={(e) => setLibrary(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="name">Holiday Name:</label>
                    <input
                        type="text"
                        id="name"
                        className="form-control"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group m-2">
                    <label htmlFor="startDate">Start Date:</label><br />
                    <DatePicker
                        id="startDate"
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="yyyy/MM/dd"
                        className="form-control"
                        required
                    />
                </div>
                <div className="form-group m-2">
                    <label htmlFor="endDate">End Date :</label><br />
                    <DatePicker
                        id="endDate"
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="yyyy/MM/dd"
                        className="form-control"
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="description">Description:</label>
                    <textarea
                        id="description"
                        className="form-control"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </div>
                <div className="form-check m-3">
                    <input
                        type="checkbox"
                        id="isRecurring"
                        className="form-check-input"
                        checked={isRecurring}
                        onChange={(e) => setIsRecurring(e.target.checked)}
                    />
                    <label htmlFor="isRecurring" className="form-check-label">Is Recurring</label>
                </div>
                <button
                    type="submit"
                    className="btn btn-primary mt-3 mb-3"
                    disabled={loading} // Disable button when loading
                >
                    {loading ? 'Creating...' : 'Create Holiday'} {/* Button text changes based on loading state */}
                </button>
            </form>
        </div>
        <Footer/>
        </>
    );
};

export default HolidayForm;
