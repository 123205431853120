import './Eschd.css';
import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Element } from 'react-scroll';
import { BASE_URL } from "./Context";

export default function Eschd() {
    const loggedIn = localStorage.getItem('accessToken');
    const [activeTab, setActiveTab] = useState('today');
    const [holidays, setHolidays] = useState({
        today: [],
        upcoming: [],
        past: [],
    });

    useEffect(() => {
        axios.get(`${BASE_URL}/api/v1/holidays/`)
            .then(response => {
                const today = new Date().toISOString().split('T')[0];
                const categorizedHolidays = {
                    today: [],
                    upcoming: [],
                    past: [],
                };

                response.data.results.forEach(event => {
                    if (event.end_date === today) {
                        categorizedHolidays.today.push(event);
                    } else if (event.end_date > today) {
                        categorizedHolidays.upcoming.push(event);
                    } else {
                        categorizedHolidays.past.push(event);
                    }
                });

                setHolidays(categorizedHolidays);
            })
            .catch(err => console.log(err));
    }, []);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <Element id="EventSched" name="EventSched">
            <div className="event-schedule-area-two pad100 my-5">
                <section id="Event">
                    <div className="container" data-aos="fade-up">
                        <div className="section-header">
                            <h2>Holidays Schedule</h2>
                            <p>All holidays are shown here</p>
                        </div>
                    </div>
                </section>
                <div className="container  mt-4 mb-4 p-3">
                    <div className="row">
                        <div className="col-lg-12">
                            <ul className="nav custom-tab pb-2" id="myTab" role="tablist">
                                <li className="nav-item">
                                    <button
                                        className={`text-white btn btn-outline-success rounded p-2 ${activeTab === 'today' ? 'active' : ''}`}
                                        onClick={() => handleTabClick('today')}
                                    >
                                        TODAY
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button
                                        className={`text-white btn btn-outline-success rounded p-2 ${activeTab === 'upcoming' ? 'active' : ''}`}
                                        onClick={() => handleTabClick('upcoming')}
                                    >
                                        UPCOMING
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button
                                        className={`text-white btn btn-outline-success rounded p-2 ${activeTab === 'past' ? 'active' : ''}`}
                                        onClick={() => handleTabClick('past')}
                                    >
                                        PAST
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content mt-5" style={{ width: '100%' }}>
                            {loggedIn ? (
                                    <>
                                        <HolidayTable title="Today's Holidays" holidays={holidays.today} isActive={activeTab === 'today'} />
                                        <HolidayTable title="Upcoming Holidays" holidays={holidays.upcoming} isActive={activeTab === 'upcoming'} />
                                        <HolidayTable title="Past Holidays" holidays={holidays.past} isActive={activeTab === 'past'} />
                                    </>
                                ) : (
                                    <div className="alert alert-warning text-center" role="alert">
                                        Please sign in to view holidays.
                                    </div>
                                )}
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </Element>
    );
}

function HolidayTable({ title, holidays, isActive }) {
    return (
        <div className={`tab-pane fade ${isActive ? 'show active' : ''}`}>
            <h5 className="text-center text-white">{title}</h5>
            <div className="table-responsive">
                <table className="table" style={{ width: '100%', border: '1px solid #198754' }}>
                    <thead>
                        <tr style={{ backgroundColor: 'black' }}>
                            <th className="text-center" scope="col">Date</th>
                            <th scope="col">Event</th>
                            <th className="" scope="col">Description</th>
                        </tr>
                    </thead>
                    <tbody className='bg-secondary'>

                        {holidays.length === 0 ? (
                            <tr className="inner-box bg-secondary">
                                <th scope="row">
                                    <div className="event-date">
                                        <p>No {title}</p>
                                    </div>
                                </th>
                                <td colSpan="2">
                                    <div className="event-wrap">
                                        <p>Same timing </p>
                                    </div>
                                </td>
                            </tr>
                        ) : (
                            holidays.map(event => (
                                <tr className="inner-box bg-secondary" key={event.id}>
                                    <th scope="row">
                                        <div className="event-date">
                                            <p>{event.end_date.split("-").reverse().join("/")}</p>
                                        </div>
                                    </th>
                                    <td>
                                        <div className="event-wrap">
                                            <p>{event.name}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="event-wrap">
                                            <p>{event.description}</p>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
