
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CDN_URL } from './Context';
import './scrollbar.css';
const LibraryChairs = ({ selectedShift ,shiftData , totalSeats, activeShift }) => {
  const [chairs, setChairs] = useState([]);

  useEffect(() => {
    if (shiftData) {
      const bookedChairs = shiftData.bookedSeats || [];


      const allChairs = Array.from({ length: totalSeats }, (v, i) => ({
        id: i + 1,
        isBooked: bookedChairs.includes(i + 1),
      }));


      setChairs(allChairs);
    }
  }, []);

  if (!shiftData) {
    return <div>No data available for this shift.</div>;
  }

  return (
    <div>

      <div className="scrollContainer" style={styles.scrollContainer}>
        <div style={styles.gridContainer}>
          {chairs.map((chair, index) => (
            chair.isBooked ? (
              <div
                key={chair.id}
                style={{
                  ...styles.chair,
                  backgroundColor: '#ff4d4d',
                  opacity: '20%',
                  cursor: 'not-allowed',
                }}
              >
                <img
                  src={(`${CDN_URL}/Images/${
                    Math.floor(index / 20) % 2 === 0 ? 'chair_90.png' : 'chair.png'
                  }`)}
                  alt={`Chair ${chair.id}`}
                  className="img-fluid"
                  style={styles.image}
                />
                <span style={styles.chairNumber}>{chair.id}</span>
              </div>
            ) : (
              <Link
                to={`/join/${activeShift}?chairId=${chair.id}`}
                key={chair.id}
                style={{ textDecoration: 'none' }}
              >
                <div
                  style={{
                    ...styles.chair,
                    backgroundColor: '#28a745',
                    opacity: '100%',
                  }}
                >
                  <img
                    src={(`${CDN_URL}/Images/${
                      Math.floor(index / 20) % 2 === 0 ? 'chair_90.png' : 'chair.png'
                    }`)}
                    alt={`Chair ${chair.id}`}
                    className="img-fluid"
                    style={styles.image}
                  />
                  <span style={styles.chairNumber}>{chair.id}</span>
                </div>
              </Link>
            )
          ))}
        </div>
      </div>
    </div>
  );
};

const styles = {
  scrollContainer: {
    overflowX: 'auto',
    padding: '15px',
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(20, 1fr)',
    gap: '10px',
    minWidth: '800px',
  },
  chair: {
    width: '100%',
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontSize: '14px',
    borderRadius: '3px',
    flexDirection: 'column',
  },
  image: {
    width: '80%',
  },
  chairNumber: {
    fontSize: '10px',
    marginTop: '2px',
  },
};

export default LibraryChairs;

















//                     className="img-fluid"
//                     style={styles.image}
//                   />
//                   <span style={styles.chairNumber}>{chair.id}</span>
//                 </div>
//               </Link>
//             )
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// const styles = {
//   scrollContainer: {
//     overflowX: 'auto',
//     padding: '15px',
//   },
//   gridContainer: {
//     display: 'grid',
//     gridTemplateColumns: 'repeat(20, 1fr)',
//     gap: '10px',
//     minWidth: '800px',
//   },
//   chair: {
//     width: '100%',
//     height: '50px',
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     color: 'white',
//     fontSize: '14px',
//     borderRadius: '3px',
//     flexDirection: 'column',
//   },
//   image: {
//     width: '80%',
//   },
//   chairNumber: {
//     fontSize: '10px',
//     marginTop: '2px',
//   },
// };

// export default LibraryChairs;
