import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { BASE_URL } from './Context';
import Navbar from './Navbar';
import Footer from './Footer';
import BottomNavbar from './BottomNavbar';

const Join = () => {
    const [shifts, setShifts] = useState([]);
    const [selectedShift, setSelectedShift] = useState('');
    const [months, setMonths] = useState(1);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { shiftId } = useParams();
    const location = useLocation();

    // Extract the chairId from the query parameters
    const queryParams = new URLSearchParams(location.search);
    const chairId = queryParams.get('chairId');
    // const shiftId = queryParams.get('shiftId');
    // console.log("shiftId",shiftId);


    useEffect(() => {
        const fetchShifts = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/api/v1/shifts/`);
                if (response.data && Array.isArray(response.data.results)) {
                    setShifts(response.data.results);
                    // Pre-select the shift using the shiftId from the URL
                    if (shiftId) {
                        setSelectedShift(shiftId.toString()); // Ensure it's a string for comparison
                        console.log("selectedShift",selectedShift);
                    }
                } else {
                    setError('Unexpected response format');
                }
            } catch (error) {
                console.error('Error fetching shifts:', error);
                setError('Error fetching shifts');
            }
        };

        fetchShifts();

        return () => {
            setShifts([]);
        };
    }, [shiftId]);

    const handleJoin = async () => {
        if (selectedShift && chairId) {
            try {
                const payload = {
                    seat_number: chairId,
                    shift: selectedShift,
                    no_of_months: months,
                };

                const token = localStorage.getItem('accessToken');

                if (!token) {
                    alert('No authentication token found. Please log in.');
                    return;
                }

                const response = await axios.post(`${BASE_URL}/api/v1/booking/`, payload, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                if (response.status === 201) {
                    alert('Seat added to Dashboard successfully, now Proceed to pay');
                    navigate('/dashboard');
                } else {
                    // Display more detailed error information
                    setError(`Failed to book seat: ${response.statusText}`);
                }
            } catch (error) {
                // Handle and log different types of errors
                if (error.response) {
                    // Server-side error
                    const { status, data } = error.response;
                    console.error('Error details:', data);
                    setError(`Error booking seat: ${status} ${data.detail || data.non_field_errors || data}`);
                } else if (error.request) {
                    // Network error
                    console.error('Network error:', error.request);
                    setError('Network error. Please check your connection.');
                } else {
                    // Client-side error
                    console.error('Client error:', error.message);
                    setError(`Error booking seat: ${error.message}`);
                }
            }
        } else {
            alert('Please select a shift and chair');
        }
    };



    const handleMonthsChange = (e) => {
        setMonths(e.target.value);
    };

    // Calculate the total price based on selected shift's price and number of months
    const calculatePrice = () => {
        const selectedShiftDetails = shifts.find(shift => shift.id.toString() === selectedShift);
        return selectedShiftDetails ? selectedShiftDetails.price * months : 0;
    };

    return (
        <>
        <Navbar/>
        <div className="container mt-5 col-md-6">
            <h1 className="text-center mb-4">Join a Shift</h1>
            <p> Your selected Seat: #<b>{chairId}</b></p>
            {error && <div className="alert alert-danger" role="alert">{error}</div>}
            <div className="form-group">
                <label htmlFor="shift">Select Shift:</label>
                {shifts.length > 0 ? (
                    <div className="form-group">
                        {shifts.map((shift) => (
                            <div key={shift.id} className="form-check">
                                <input
                                    type="radio"
                                    id={`${shift.id}`}
                                    name="shift"
                                    className="form-check-input"
                                    value={shift.id}
                                    checked={selectedShift === shift.id.toString()} // Ensure comparison with string
                                    onChange={(e) => setSelectedShift(e.target.value)}
                                />
                                <label htmlFor={`${shift.id}`} className="form-check-label">
                                    {shift.start_time} - {shift.end_time} (₹{shift.price})
                                </label>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="alert alert-info" role="alert">No shifts available</div>
                )}
            </div>
            <div className="form-group mt-3">
                <label htmlFor="months">Number of Months:</label>
                <input
                    type="number"
                    id="months"
                    className="form-control"
                    value={months}
                    onChange={handleMonthsChange}
                    min="1"
                />
            </div>
            <div className="form-group mt-3">
                <label>Total Price: ₹{calculatePrice()}</label>
            </div>
            <button
                className="btn btn-primary m-3"
                onClick={handleJoin}
                disabled={!selectedShift || !chairId}
            >
                Join & Proceed to Pay
            </button>
        </div>
        <BottomNavbar/>
        <Footer/>
        </>
    );
};

export default Join;
