import React from 'react'
import { LIBRARY_NAME, LIBRARY_ADDRESS,LIBRARY_ADMIN_1_NAME,LIBRARY_ADMIN_2_NAME,LIBRARY_CONTACT1,LIBRARY_CONTACT2 } from './Context'
// import { Link } from 'react-router-dom'

export default function Extra() {
    return (
        <div>

            <section id="about">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <h2 style={{ textDecoration: 'none', color: "#ffff" }}>About {LIBRARY_NAME}</h2>
                            <p style={{ textDecoration: 'none', color: "white", opacity: "100%" }}>Welcome to {LIBRARY_NAME}, a serene and resourceful space dedicated to providing students with a comfortable environment to study and read. Our library offers a range of amenities including climate control with air conditioning, fan, and adjustable lighting to ensure an optimal reading experience. At {LIBRARY_NAME}, we are committed to creating a supportive and quiet space where students can focus and excel in their academic pursuits. Additionally, we offer our services at minimal prices compared to other facilities, making quality study spaces accessible for all.</p>
                        </div>
                        <div className="col-lg-3 mx-4">
                            <h3>Where</h3>
                            <p>{LIBRARY_ADDRESS}</p>
                            <h3>Owners</h3>
                            <p> {LIBRARY_ADMIN_1_NAME} ({LIBRARY_CONTACT1})
                            <br/>
                                {LIBRARY_ADMIN_2_NAME} ({LIBRARY_CONTACT2})
                            </p>
                            <p></p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
