import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { decodeToken } from 'react-jwt';
import { useNavigate } from 'react-router';
import Footer from './Footer';
import Navbar from './Navbar';
import { BASE_URL } from './Context';

function UpdateProfile() {
    const userToken = localStorage.getItem('accessToken');
    const decode = decodeToken(userToken);
    const navigate = useNavigate();

    const [userProfile, setUserProfile] = useState({});
    const [errors, setErrors] = useState({});
    const [nameUpdate, setNameUpdate] = useState({ first_name: '', last_name: '' , email:'', username:''});

    useEffect(() => {
        // Fetch user profile data
        axios.get(`${BASE_URL}/api/v1/userprofiles/${decode.user_id}/`)
            .then(res => setUserProfile(res.data))
            .catch(err => console.log(err.message));

        // Fetch user name data
        axios.get(`${BASE_URL}/accounts/profile/${decode.user_id}`, {
            headers: {
                'Authorization': `Bearer ${userToken}`,
            }
        })
        .then(res => {
            setNameUpdate({
                first_name: res.data.first_name,
                last_name: res.data.last_name,
                email: res.data.email,
                username: res.data.username,
            });
        })
        .catch(err => console.log(err.message));
    }, [decode.user_id, userToken]);

    const handleChange = (e) => {
        setUserProfile({ ...userProfile, [e.target.name]: e.target.value });
    };

    const handleNameChange = (e) => {
        setNameUpdate({ ...nameUpdate, [e.target.name]: e.target.value });
    };

    const updateProfile = (e) => {
        e.preventDefault();
        const updatedData = { ...userProfile, ...nameUpdate };
        console.log("updated data===", updatedData)
        axios.put(`${BASE_URL}/accounts/profile/${decode.user_id}`, nameUpdate, {
            headers: {
                'Authorization': `Bearer ${userToken}`,
                'Content-Type': 'application/json'
            }
        })
        .then(() => {
            alert('Name Updated');
            navigate('/dashboard');
        })

        axios.put(`${BASE_URL}/api/v1/userprofiles/${decode.user_id}/`, updatedData, {
            headers: {
                'Authorization': `Bearer ${userToken}`,
                'Content-Type': 'application/json'

            }
        }).then(() => {
            console.log("Profile updated");
            alert('Profile Updated');
        })
        .catch(err => {
            if (err.response && err.response.data) {
                setErrors(err.response.data);
            } else {
                alert('Profile not updated');
                console.log('Error-', err);
            }
        });
    };

    return (
        <div>
            <Navbar />
            <h2 className="mx-1 my-1 text-center p-2" style={{ color: "white" }}>
                Hi <strong style={{ color: "red" }}>{userProfile.first_name || nameUpdate.first_name}</strong>, Update your profile here...!
            </h2>
            <div className="container">
                <div className="main-body">
                    <form onSubmit={updateProfile}>
                        <div className="row gutters-sm">
                            <div className="col-md-4 mb-3">
                                <div className="profilecard">
                                    <div className="profilecard-body">
                                        <div className="d-flex flex-column align-items-center text-center">
                                            <img
                                                src={"https://bootdey.com/img/Content/avatar/avatar7.png"}
                                                alt="Admin"
                                                className="rounded-circle"
                                                width={"150"}
                                            />
                                            <div className="mt-3">
                                                <h4 style={{ textDecoration: 'none', color: "aliceblue", textTransform: "uppercase" }}>
                                                    {userProfile.first_name || nameUpdate.first_name}
                                                </h4>
                                                <input
                                                    type='text'
                                                    name='bio'
                                                    className="col-sm form-control mb-1"
                                                    onChange={handleChange}
                                                    value={userProfile.bio || ''}
                                                    placeholder="Update Bio"
                                                />
                                                {errors.bio && <div className="text-danger">{errors.bio}</div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="profilecard mt-3">
                                    <div className="profilecard-body">
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap bg-transparent">
                                                <h6 className="mb-0" style={{ color: "blue" }}>Facebook</h6>
                                                <input
                                                    className="form-control"
                                                    name='facebook'
                                                    onChange={handleChange}
                                                    value={userProfile.facebook || ''}
                                                    placeholder="Update Facebook URL"
                                                />
                                                {errors.facebook && <div className="text-danger">{errors.facebook}</div>}
                                            </li>
                                            <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap bg-transparent">
                                                <h6 className="mb-0" style={{ color: "skyblue" }}>Twitter</h6>
                                                <input
                                                    className="form-control"
                                                    name='twitter'
                                                    onChange={handleChange}
                                                    value={userProfile.twitter || ''}
                                                    placeholder="Update Twitter URL"
                                                />
                                                {errors.twitter && <div className="text-danger">{errors.twitter}</div>}
                                            </li>
                                            <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap bg-transparent">
                                                <h6 className="mb-0" style={{ color: "red" }}>Instagram</h6>
                                                <input
                                                    className="form-control"
                                                    name='instagram'
                                                    onChange={handleChange}
                                                    value={userProfile.instagram || ''}
                                                    placeholder="Update Instagram URL"
                                                />
                                                {errors.instagram && <div className="text-danger">{errors.instagram}</div>}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-8">
                                <div className="profilecard mb-3">
                                    <div className="profilecard-body">
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <h6 className="mb-0" style={{ textDecoration: 'none', color: "black" }}>First Name</h6>
                                            </div>
                                            <div className="col-sm-9">
                                                <input
                                                    type='text'
                                                    name='first_name'
                                                    className="form-control"
                                                    onChange={handleNameChange}
                                                    value={nameUpdate.first_name || ''}
                                                    placeholder="First Name"
                                                />
                                                {errors.first_name && <div className="text-danger">{errors.first_name}</div>}
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <h6 className="mb-0" style={{ textDecoration: 'none', color: "black" }}>Last Name</h6>
                                            </div>
                                            <div className="col-sm-9">
                                                <input
                                                    type='text'
                                                    name='last_name'
                                                    className="form-control"
                                                    onChange={handleNameChange}
                                                    value={nameUpdate.last_name || ''}
                                                    placeholder="Last Name"
                                                />
                                                {errors.last_name && <div className="text-danger">{errors.last_name}</div>}
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <h6 className="mb-0" style={{ textDecoration: 'none', color: "black" }}>Email</h6>
                                            </div>
                                            <div className="col-sm-9">
                                                <input
                                                    type='email'
                                                    name='email'
                                                    className="form-control"
                                                    onChange={handleChange}
                                                    value={userProfile.email || ''}
                                                    placeholder="Email"
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <h6 className="mb-0" style={{ textDecoration: 'none', color: "black" }}>Mobile</h6>
                                            </div>
                                            <div className="col-sm-9">
                                                <input
                                                    type='text'
                                                    name='mobile_number'
                                                    className="form-control"
                                                    onChange={handleChange}
                                                    value={userProfile.mobile_number || ''}
                                                    placeholder="Mobile Number"
                                                />
                                                {errors.mobile_number && <div className="text-danger">{errors.mobile_number}</div>}
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <h6 className="mb-0" style={{ textDecoration: 'none', color: "black" }}>Address</h6>
                                            </div>
                                            <div className="col-sm-9">
                                                <input
                                                    type='text'
                                                    name='address'
                                                    className="form-control"
                                                    onChange={handleChange}
                                                    value={userProfile.address || ''}
                                                    placeholder="Address"
                                                />
                                                {errors.address && <div className="text-danger">{errors.address}</div>}
                                            </div>
                                        </div>
                                        <hr />
                                    </div>
                                </div>
                                <button type="submit" className="btn btn-primary">Update Profile</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default UpdateProfile;
