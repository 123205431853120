import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './intro.css';
import { LIBRARY_NAME } from './Context';

export default function Intro() {
  const [userName, setUserName] = useState('User');
  const token = localStorage.getItem('accessToken');

  useEffect(() => {
    if (token) {
      const savedUsername = localStorage.getItem('username') || 'User';
      if (savedUsername) {
        setUserName(savedUsername);
      } else {
        console.error('Username not found in localStorage');
      }
    }
  }, [token]);

  return (
    <div>
      <section id="intro">
        <div className="intro-container mt-5">
          <h3 className="text-light">Welcome! {userName} to </h3>
          <h1 className="mb-4 pb-0"> <span>{LIBRARY_NAME}</span> <br />
            <p style={{ fontSize: "3.9rem" }} className="bounce">
              J
              <span className="char">
                <div className="col-lg-3">
                  <Link style={{ color: 'transparent' }} to='/signup/' className='play-btn scrollto rotate360'></Link>
                </div>
              </span>
              in
            </p>
          </h1>
          <h1><span> Space of Success</span></h1>
          <p className="mb-4 pb-0">Your one-stop destination for a quiet and comfortable study experience</p>
          <Link to='/create-holiday' className='btn btn-primary' >Add Holiday</Link>
          <Link to="/about" className="about-btn scrollto">About Us</Link>
        </div>
      </section>
    </div>
  );
}
